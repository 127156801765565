/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { cloneDeep, keyBy, isEqual, mapValues, forEach, get, some, debounce, uniqWith, set } from 'lodash';
import { getPatchedDataSchema } from '@tencent/data-schema-core';
import { patchVueInstance } from '@tencent/ui-core/lib/utils/vue/patchVueInstance';
import { getFitDataLayout } from '@tencent/ui-core/lib/utils';
import { createSourceController } from './sourceControllerFactories';
import extraUICoreComponents from './uicoreComponents';
import { getExtraUICoreFlowAtoms } from './flowAtoms';
import { getXyHideModalAtom } from './flowAtoms/uiAtoms';
import UICoreAntdUILib from './uiLib';
import { UC_TAG_SCOPEDSCHEMA } from './consts';
import { getWDataSchema } from '@utils/global/apiSchema';
import { buildAppDataSchema } from './declaration';
import { uiCoreBuiltinComponents, UCBC_ALWAYS_HIDE, UCBC_EDITOR } from './UICoreBuiltinComponents';
import scfLoader from '@loaders/scf/loader';
import { schemaOfUtilsForUser, utilsForUser } from '@tencent/wuji-utils';
import { fromQueryString, toQueryString } from './utils/queryType';
import { getAllCardListLayouts } from './utils/getCardListLayout';
// import { tryEval } from '@tencent/ui-core/lib/utils';
import { resolveVueInject } from '@tencent/ui-core/lib/utils/vue/resolveVueInject';
import { WUJI_COMPONENTS } from '@/config/constant';
import { getScfHostExtends } from '@/loaders/scf/utils';
import { wujiTipsSchema } from '@tencent/wuji-source';
import logger from '@utils/logger';
import { cgiAtom } from './flowAtoms/sourceAtoms';
import { isInComponentEditor } from '@/pages/project/pageConfig/pagelet-component-editor/utils';
import { collect } from '@components/uicorePlugin/utils/collect';
import { getAvailableComponent, getCompInfoFromCompKey, getHiddenComponents } from '@/utils/comps-loader';
import { patchDevtools } from '@components/uicorePlugin/devTools/patchDevtools';
import { isRegexPath } from '@utils/path';

// 无极字段类型
const WUJI_COMPONENTS_TYPES = [...new Set(Object.values(WUJI_COMPONENTS).map(item => item.type))];

const patchedSchemaOfUtilsForUser = getPatchedDataSchema(schemaOfUtilsForUser);

export const EVENT_DS_READY = 'datasource:ready';
export const EVENT_CREATED = 'created';

/**
 * @typedef {import('../../../../ui-core/types')} UICore
 */

const PluginBase = Vue.extend({
  name: 'UICoreXiaoyaoPlugin',
  props: {
    xyPageConfig: { default: null }, // 设计模式专用的，相当于 src/pages/project/pageConfig/index.vue
    materialType: {
      default: '',
    },
  },
  data() {
    return {
      loadingSchema: 0,
      renderer: null,
      uc: null,       // init 时初始化
      designer: null, // init 时初始化
      sourceConfigs: {},    // 当前页面的 xySources 配置应用（转换成了 dict）
      sources: {},  // controllers
      data: {},     // rootData
      dataSchema: {
        type: 'object',
        fields: [],
      },
    };
  },
  computed: {
    uiLib() {
      return UICoreAntdUILib;
    },
    isDesignMode() {
      return !!this.renderer?.uc.isDesignMode;
    },
  },
  created() {
    this.$parent?.$on('hook:beforeDestroy', () => this.$destroy());
  },
});
/**
 * 这是一个 ui-core 插件，封装了一些迷之逍遥数据逻辑。
 *
 * 注意！一个 uc-renderer / uc-designer 只能对应一个 UICoreXiaoyaoPlugin 实例！否则会串数据！
 */
export default class UICoreXiaoyaoPlugin extends PluginBase {
  /**
   * @param {import('../../../../ui-core/types/core').UcBus} uc
   */
  install(uc) {
    uc.hooks.componentMetaListAfterLoad.tap('XiaoyaoPlugin', list => this.patchComponentMetaList(list));
    uc.hooks.flowAtomListAfterLoad.tap('XiaoyaoPlugin', (list) => {
      const target = list.find(item => item.id === 'uicore:invokeDataSourceMethod');
      if (target) {
        target.visible = false;
        target.name = '数据源操作（旧版）';
      }
      const enableRequestCgiAtom = this.xyPageConfig?.projectInfo?.dataSourceConfig?.enableRequestCgiAtom;
      cgiAtom.visible = !!enableRequestCgiAtom;// cgiAtom就在extraUICoreFlowAtoms里面

      // 根据页面类型插入 '关闭弹窗' 事件行为
      const newAtoms = [...getExtraUICoreFlowAtoms(uc)];
      const xyShowModalAtomIndex = newAtoms.findIndex(item => item.id === 'xy:showModal');
      if (xyShowModalAtomIndex > -1) {
        const xyType = uc?.renderer?.ucPagelet?.xyType;
        newAtoms.splice(xyShowModalAtomIndex + 1, 0, getXyHideModalAtom(xyType));
      }

      // 如果是在线组件，把事件转发的行为原子变成可见
      if (isInComponentEditor()) {
        const forwardEventAtom = newAtoms.find(item => item.id === 'pageletComponent:eventEmitter');
        forwardEventAtom.visible = true;
      }

      return [...list, ...newAtoms];
    });
    uc.hooks.pageletRendererBeforeCreate.tap('XiaoyaoPlugin', renderer => this.patchCRenderer(renderer));
    uc.hooks.fitDataAfterAll.tap({ name: 'XiaoyaoPluginPatch', before: 'FormPlugin' }, (list) => {
      const omniIdx = list.findIndex((x) => {
        const { layout } = getFitDataLayout(x);
        return layout.type === 'wujiOmniInput';
      });
      if (omniIdx === -1) return;
      list.splice(omniIdx, 1);
    });
    uc.hooks.fitDataAfterAll.tap('XiaoyaoPlugin', (oldList, req) => {
      if (req.dataPath === 'data') return []; // 根节点不允许折腾

      let list = [...oldList];
      const schema = req.dataSchema;
      const source = req.renderer.sources[schema['xy:sourceId']];

      // 数据源操作按钮 + 数据源的 fitDataAfterAll钩子
      if (source) {
        const formItemIndex = list.findIndex(x => x.layout.type === 'w-form-item');
        if (formItemIndex >= 0) list.splice(formItemIndex, 1);

        const sourceId = source.config.id;
        source.data?.stateSchema?.methods?.forEach((method) => {
          list.push({
            name: method.title,
            icon: 'https://vfiles.gtimg.cn/vupload/20210427/938d481619528238025.png',
            type: 'action',
            layout: {
              type: getAvailableComponent('public', 'w-button'),
              props: {
                btnText: `${method.title} (${sourceId})`,
              },
              events: {
                click: {
                  steps: [
                    method.isDangerous && {
                      type: 'uicore:confirm',
                      params: {
                        text: '真的要执行操作吗？',
                        abortIfRejected: true,
                      },
                      backfill: '',
                    },
                    {
                      type: 'xy:invokeDataSourceMethod',
                      params: {
                        dataPath: `data.${sourceId}`,
                        methodName: method.id,
                      },
                      backfill: '',
                    },
                    // {
                    //   type: 'xy:source:dispatch',
                    //   params: {
                    //     action: {
                    //       sourceId,
                    //       action: method.id,
                    //     },
                    //   },
                    //   backfill: '',
                    // },
                  ].filter(Boolean),
                },
              },
            },
          });
        });

        // 数据源的钩子
        list = source?.fitDataAfterAll?.(list, req) || list;
      }


      // 移除UICore内置的编辑器组件
      {
        const listWithoutUCBuiltinEditors = list.filter((x) => {
          const { layout } = getFitDataLayout(x);
          const flags = uiCoreBuiltinComponents[layout.type];
          if (!flags) return true;        // 不是被特殊对待的组件
          return (flags & UCBC_EDITOR) === 0;   // 是被特殊对待的组件，但是不需要被隐藏
        });

        // 如果移除了内置编辑器组件后，仍然有有效的编辑器组件，那就安全地删除！
        if (listWithoutUCBuiltinEditors.some((x) => {
          const { layout } = getFitDataLayout(x);
          return x.type === 'editor' && layout.type !== 'w-form-item';
        })) {
          list = listWithoutUCBuiltinEditors;
        }
      }

      // 把原来的“默认编辑器”组件去掉，如果OK的话
      const toRemoveIndex = list.findIndex((x) => {
        const { layout } = getFitDataLayout(x);
        return layout.type === 'w-form-auto-editor';
      });
      if (toRemoveIndex >= 0 && list.some((x, i) => x.type === 'editor' && i !== toRemoveIndex)) {
        list.splice(toRemoveIndex, 1);
      }

      // 列表组件拖出来默认的layout,
      // TODO, 上面再盖一层来获取 "type" 为complex 还是其他的待拓展模版
      const arrayRes = list.find((x) => {
        const { layout } = getFitDataLayout(x);
        return layout.type === 'w-array' && layout.overwrite !== false;
      });
      if (arrayRes) {
        arrayRes.displayMode = 'column';
        arrayRes.layouts = getAllCardListLayouts(arrayRes.layout, schema.items);
      }
      return list;
    });
    /* 调整推荐组件顺序的逻辑 */
    uc.hooks.fitDataAfterAll.tap('XiaoyaoOrderPlugin', (oldList, req) => {
      const type = req?.dataSchema?.type;
      // 重排 '带标签的xxx' 的顺序
      if (!['any', 'object', 'array'].includes(type)) {
        const tagItemIndex = oldList.findIndex(i => i.name?.startsWith('带标签的'));
        if (tagItemIndex > -1) {
          const tags = oldList.splice(tagItemIndex, 1);
          oldList.splice(tagItemIndex + 1, 0, ...tags);
        }
      }

      // 无极字段类型去重
      const uniqWujiList = uniqWith(oldList, (a, b) => {
        const equal = a?.layout?.type === b?.layout?.type;
        return equal && WUJI_COMPONENTS_TYPES.includes(b?.layout?.type);
      });

      return uniqWujiList;
    });
    set(uc.config, 'flowErrorLogger', Vue.prototype.$tips);
  }

  /**
   * 为CRenderer（实际渲染的容器 & LessCode 运行环境）注入一些上下文东西
   */
  patchCRenderer(renderer, externalPlugin) {
    // 允许通过外部传入plugin实例，来修改这个cRenderer中指向的plugin
    // 一个例子，在在线组件中，每一个cRenderer对应的plugin都是不同的
    const plugin = externalPlugin ? externalPlugin : this;
    // 有外部传入的renderer时，一般是在线组件使用的cRenderer，这时候
    // 需要初始化这个cRenderer上的数据源，哪怕它不是root renderer
    const isRootRendererOfPageletComponent = !!externalPlugin;

    // 把当前 uc 的 renderer 实例赋值到 wContext.lessCode 中
    // 在这个阶段注入，避免 created 时已经晚了
    const wContext = resolveVueInject(renderer, 'wContext');
    const w = resolveVueInject(renderer, 'w');

    if (renderer.$options?.propsData?.ucInternalIsRoot || isRootRendererOfPageletComponent) {
      if (wContext) wContext.lessCode = renderer;
      if (w) w.renderer = renderer;
    }

    patchVueInstance(renderer, {
      inject: {
        ctx: { from: 'wContext', default: null },
        w: { from: 'w', default: null },
        wContext: { from: 'wContext', default: null },
        rootContext: { from: 'rootContext', default: null },
      },
      computed: {
        sources: {
          get: () => this.sources,
        },
        ucXyPlugin: {
          get: () => this,
        },
        $utils() {
          return utilsForUser;
        },
        refs() {
          return {};
        },
      },
      beforeCreate() {
        // 会有state数据源依赖$query所以先给个一次性的
        this.$query = this.$route.query;
        // 为了防止报错给塞一个空的props
        this.props = Vue.observable({});
      },
      created() {
        if (this.ucIsRoot) {
          this.ucXyPlugin.$emit(EVENT_CREATED, renderer);
          performance.mark('UI_CORE_START_RENDERING');
          logger.info('[UICoreXiaoyaoPlugin] Init for CRenderer: ', renderer);
          plugin.uc = renderer.uc;
          Object.defineProperty(plugin, 'designer', {
            get() {
              return renderer.uc.designer;
            },
            configurable: true,
          });
        } else {
          // 作用域插槽注入上级的$query
          if (renderer.ucRootRenderer?.$query) {
            this.$query = renderer.ucRootRenderer?.$query;
          }
        }

        // 针对主页面和页面片/弹窗的 mockPathParams $Query / props 的注入顺序调整
        const isMainPagelet = this.ucPagelet.id === 'default';
        const initOrder = isMainPagelet
          ? ['initMockPathParams', 'init$Query', 'initPageletProps']
          : ['initPageletProps', 'initMockPathParams', 'init$Query'];
        initOrder.forEach((item) => {
          plugin[item](this);
        });

        // 全局上下文
        this.addDataSourceDeclaration({
          id: 'w',
          schema: getWDataSchema(),
        });

        this.addDataSourceDeclaration({
          id: '$utils',
          schema: patchedSchemaOfUtilsForUser,
        });

        this.addDataSourceDeclaration({
          id: wujiTipsSchema.id || '$tips',
          schema: wujiTipsSchema,
        });

        const rootRendererQuerySchema = this.ucRootRenderer?.ucDataSources?.$query?.schema;
        if (!this.ucIsRoot && rootRendererQuerySchema) {
          // 作用域插槽内部补全$query的schema
          const schema = cloneDeep(rootRendererQuerySchema);
          // 在子renderer里面要禁止从数据源面板编辑$query
          schema.fields?.forEach(item => (item[UC_TAG_SCOPEDSCHEMA] = true));
          this.addDataSourceDeclaration({
            id: '$query',
            schema: { ...schema, 'xy:scopedSchema': true },
          });
        }
        // 全局实例
        const { $app } = Vue.prototype;
        if ($app) {
          this.addDataSourceDeclaration({
            id: '$app',
            schema: buildAppDataSchema($app),
          });
        }
        // 对于根页面片，需要做一些特殊逻辑
        if (this.ucIsRoot || isRootRendererOfPageletComponent) {
          // 把当前 uc 的 renderer 实例赋值到 wContext.lessCode 中
          if (this.wContext && this.wContext.lessCode !== this) {
            this.wContext.lessCode = this;
          }

          plugin.initDataSources(this);

          const { query } = this.uc.$route;
          // 在created阶段，组件还没有被渲染，devtool就开始捕获
          if (query?.dt_hide !== '1' && query?.dt_recording === '1') {
            this.uc.root.getDtBus?.({ recording: true });
          }
        }

        plugin.initCollectionRefs?.(this);
      },
      mounted() {
        if (this.ucIsRoot) {
          const { uc } = this;
          if (uc.$route.query?.dt_hide !== '1' && uc.root?.openDevtool) {
            patchDevtools(uc, this);
          }
        }
      },
      methods: {
        /**
         * 获取数据源控制器实例
         *
         * @param {string} dataPath 是数据源ID，或者形如 `data.xxx` 的 dataPath
         */
        getDataSourceOf(dataPath) {
          let source = plugin.sources[dataPath];
          if (!source) {
            const tmp = /^data\.(\w+)/.exec(dataPath);
            source = plugin.sources[tmp && tmp[1]];
          }
          return source;
        },

        /**
         * 调用云函数，内部指向云函数loader->自动抹平各环境请求云函数差异
         */
        callFunc(funcName, axiosOptions) {
          return scfLoader.callFunc({
            funcName,
            projectId: this.wContext.projectConfig.id,
            ...getScfHostExtends(),
          }, axiosOptions);
        },
      },
    });
  }

  /**
   * 改造一些原有的组件 & 注入一些新的组件
   *
   * @param {import('../../../../ui-core/types').ComponentMeta[]} list
   */
  patchComponentMetaList(list) {
    const newList = list.map((item) => {
      // 在基础组件里，展示 FormPlaceholder 这个组件
      if (item.id === 'w-form') {
        const originalFitData = item.fitData;
        return {
          ...item,
          visible: false,
          presets: [],
          fitData(...args) {
            const ans = originalFitData?.apply(this, args);
            const cardLayout = ans?.layout?.children?.[0];

            if (cardLayout && cardLayout.type === 'w-card') {
              // options.cardTitle 来决定是否要在推导的card中默认启用title
              // 这其实是一个历史遗留问题 随着w-card-2v的迭代 title===false的时候就不会显示title了
              // 所以必须通过一个配置项来让转换过来的card-2v启用title 不然可能会发生存量的推导突然出现title
              const { options } = args[0];
              // 将 UICore 的基础卡片组件，替换为公共组件
              const title = cardLayout.props?.title || '';
              cardLayout.type = 'public-w-card-2v';
              cardLayout.props = {
                title: !!options?.cardTitle,
              };
              cardLayout.slots = {
                title: [
                  {
                    type: 'w-paragraph',
                    children: [
                      {
                        type: 'public-w-title',
                        props: { isIcon: true, value: title },
                      },
                    ],
                  },
                ],
              };
            }
            return ans;
          },
        };
      }

      // 把原本的纯文本组件干掉
      if (item.id === 'w-text') {
        return {
          ...item,
          fitData: () => null,
          presets: [],
          visible: false,
        };
      }

      // 组件替换逻辑
      const UCBCFlags = uiCoreBuiltinComponents[item.id] || 0;
      if (UCBCFlags & UCBC_ALWAYS_HIDE) item.visible = false;

      return item;   // no change
    });

    const hiddenComponent = getHiddenComponents();

    // 公共组件里有相同的（比如 public-w-card 要替换 w-card），要做替换
    Object.keys(uiCoreBuiltinComponents).forEach((bareId) => {
      if (hiddenComponent?.hideInServer?.includes(bareId) || hiddenComponent?.hideInClient?.includes(bareId)) {
        const component = newList.find(it => it.id === bareId);
        if (component) {
          component.visible = false;
        }
      }

      const publicComponent = newList.find((it) => {
        const { keyword } = getCompInfoFromCompKey(it.id) || {};
        return keyword === bareId;
      });
      if (!publicComponent) return;

      const newItem = {
        ...publicComponent,
        id: bareId,
        visible: false,
        presets: [],
        fitData: () => null,
        initialLayout: null,
        placeCompInside: null,
      };

      const oldIndex = newList.findIndex(it => it.id === bareId);
      if (oldIndex === -1) newList.push(newItem);
      else newList.splice(oldIndex, 1, newItem);
    });

    // 插入新的 arrayTable，提供 “引入已有” 的能力
    newList.unshift(...extraUICoreComponents);

    return newList;
  }


  /**
   * 统一$query的示例值和默认值
   * @param {import('@tencent/ui-core/lib/renderer/CRenderer').CRenderer} renderer
   */
  init$Query(renderer) {
    if (!renderer.ucIsRoot) return;
    const isDesignMode = !!renderer.uc.isDesignMode;
    const pagelet = renderer.ucPagelet;

    const queryReplace = collect((a, b) => ({ ...a, ...b }), {}, (getPayload, reset) => {
      this.$nextTick(() => {
        const query = { ...renderer.$route.query, ...getPayload() };
        reset();
        renderer.$router.replace({
          query,
          // Hack: 让 beforeRouteUpdate 守卫函数不刷新 UcRenderer
          params: { block_reload_uc_renderer: true },
        }).catch(() => void 0);
      });
    });
    function addQueryDataSource() {
      const querySchemaFields = keyBy(pagelet.querySchema?.fields, 'id');

      const queryInstance = {};

      const TAG_SYNC = 'xy:sync';
      const execWatchFuns = [];
      forEach(querySchemaFields, (field, id) => {
        const getValueFromQuery = () => {
          const ret = fromQueryString(renderer.$route.query[id], field);
          // query里没有就取默认值
          if (ret.default) {
            return cloneDeep((isDesignMode ? field.example : void 0) ?? field.default);
          }
          return ret.value;
        };
        const sync = field[TAG_SYNC];
        let val = getValueFromQuery();
        Object.defineProperty(queryInstance, id, {
          enumerable: true,
          configurable: true,
          get: () => val,
          set: (v) => {
            val = v;
          },
        });

        const watchRouteQuery = () => {
          renderer.$watch(
            () => renderer.$route.query[id],
            (newValue, oldValue) => {
              if (isEqual(newValue, oldValue)) return;
              // 开了双向绑定就会出现人工抹掉query的情况 这时候找不到就不用管了
              if (sync && renderer.$route.query[id] === undefined && newValue === undefined) {
                return;
              }
              // 同步query的值
              renderer.$query[id] = getValueFromQuery();;
            },
            { deep: true },
          );
        };
        execWatchFuns.push(watchRouteQuery);
        // 编辑时不处理双绑逻辑
        if (sync && !isDesignMode) {
          const watchQuery = () => {
            renderer.$watch(
              () => renderer.$query[id],
              (newValue, oldValue) => {
                if (isEqual(newValue, oldValue)) return;
                const queryPatch = { [id]: toQueryString(newValue, field) };
                // $query赋值为undefined用来移除url
                if (newValue === undefined) {
                  queryPatch[id] = undefined;
                }
                // 去除冗余跳转
                if (renderer.$route.query[id] === queryPatch[id]) return;
                // reduce到nextTick统一执行$router.replace
                queryReplace(queryPatch);
              },
              { deep: true, immediate: true },
            );
          };
          // 需要深度watch来写入query
          execWatchFuns.push(watchQuery);
        }
      });
      const isMainPagelet = pagelet.id === 'default';

      const fields = (pagelet.querySchema?.fields || []).map(item => ({
        'uc:tag': item['xy:sync'] ? '可双向绑定' : null,
        ...item,
      }));
      const obInstance = Vue.observable(queryInstance);
      const instanceProxy = new Proxy(obInstance, {
        get(target, p, receiver) {
          if (p in querySchemaFields || p === '___ob__') {
            return Reflect.get(target, p, receiver);
          }
          return Reflect.get(renderer.$route.query, p, receiver);
        },
        set(target, p, value, receiver) {
          if (p in querySchemaFields || p === '___ob__') {
            return Reflect.set(target, p, value, receiver);
          }
          console.error(`请在数据源->URL参数中定义$query.${p} 并开启双向绑定 即可修改query`);
        },
        ownKeys(target) {
          return Array.from(new Set([...Reflect.ownKeys(target), ...Reflect.ownKeys(renderer.$route.query)]));
        },
        getOwnPropertyDescriptor(target, p) {
          if (p in querySchemaFields || p === '___ob__') {
            return Reflect.getOwnPropertyDescriptor(target, p);
          }
          return Reflect.getOwnPropertyDescriptor(renderer.$route.query, p);
        },
      });

      renderer.addDataSource({
        id: '$query',
        schema: {
          title: 'URL参数',
          type: 'object',
          fields,
          'uc:bindable': false,
          // 非主页面时 用$query会低频一点
          'uc:collapsed': isMainPagelet === false,
        },
        instance: Vue.observable(instanceProxy),
      });


      execWatchFuns.forEach(execWatch => execWatch());
    }
    if (isDesignMode) {
      renderer.$watch(() => renderer.ucPagelet.querySchema, addQueryDataSource, { deep: true });
    }
    addQueryDataSource();
  }

  initCollectionRefs(renderer) {
    renderer.addDataSource({
      id: 'collectionRefs',
      schema: {
        title: '数据源依赖',
        type: 'object',
        fields: [],
        'uc:bindable': false,
        'uc:collapsed': true,
      },
      instance: {},
    });
  }

  initMockPathParams(renderer) {
    const isDesignMode = !!renderer.uc.isDesignMode;
    if (!isDesignMode) return;

    renderer.$watch(() => {
      // 只有包含动态路径参数的页面才需要模拟路由动态参数
      const path = renderer?.ucXyPlugin?.xyPageConfig?.baseData?.path;
      return !!(path && isRegexPath(path));
    }, (needMockPathParams) => {
      renderer.addDataSource({
        id: 'mockPathParams',
        schema: {
          title: '模拟路由动态参数',
          type: 'object',
          fields: [],
          hidden: !needMockPathParams,
          'uc:bindable': false,
          'uc:collapsed': false,
          'uc:allowCopyPath': false,
        },
        instance: {},
      });
    }, { immediate: true });
  }

  /**
   * @param {import('@tencent/ui-core/lib/renderer/CRenderer').CRenderer} renderer
   */
  initPageletProps(renderer) {
    if (!renderer.ucIsRoot) return;
    const isDesignMode = !!renderer.uc.isDesignMode;

    function addDataSourceNamedProps() {
      const fields = keyBy(renderer.ucPagelet.propsSchema?.fields, 'id');
      const props = {};

      const temp1 = { ...fields };

      const { data } = renderer.wContext;
      forEach(
        data,
        (_, key) => {
          Object.defineProperty(props, key, {
            enumerable: true,
            configurable: true,
            get: () => data[key],
            set: (v) => {
              Vue.set(data, key, v);
            },
          });

          if (key in temp1) {
            temp1[key] = null;
          } else {
            fields[key] = { id: key, type: 'any' };
          }
        },
      );
      // 收集watch回调 在addDataSource之后执行
      const watchCallbackArray = [];
      // 检测到是从url直接打开的页面 并且不在设计模式
      const isOuterPage = !renderer?.ctx?.parent && !renderer.uc.isDesignMode;
      // 兼容从wContext.data传过来的东西(但是url直接打开的页面正常情况下data为空)
      isOuterPage && forEach(temp1, (value, id) => {
        // overrided by wContext.data
        if (!value) return;
        // 新的query逻辑分离到$query里了 不走这里了
        if (value['xy:disableQuery']) return;
        const getValueFromQuery = () => {
          const ret = fromQueryString(renderer.$route.query[id], fields[id]);
          // query里没有就取默认值
          if (ret.default) {
            return cloneDeep((isDesignMode ? value.example : void 0) ?? value.default);
          }
          return ret.value;
        };
        // 变量实例
        let val = getValueFromQuery();
        Object.defineProperty(props, id, {
          enumerable: true,
          configurable: true,
          get: () => val,
          set: (v) => {
            val = v;
          },
        });
        // 监听$route.query 让参数的更改实时响应到props上
        watchCallbackArray.push(() => {
          renderer.$watch(() => renderer.$route.query[id], (newValue, oldValue) => {
            if (isEqual(newValue, oldValue)) return;
            // 开了双向绑定就会出现人工抹掉query的情况 这时候找不到就不用管了
            if (value['xy:sync'] && renderer.$route.query[id] === undefined) {
              return;
            }
            renderer.props[id] = getValueFromQuery();
          }, { deep: true });
        });
        // 如果开了双向绑定就要把props写回query
        if (value['xy:sync']) {
          // replaceurl不会自动刷页面之后无需长时间debounce了
          const routeDebounce = debounce(callback => callback(), 1);
          // 需要深度watch来写入query
          watchCallbackArray.push(() => {
            renderer.$watch(
              () => renderer.props[id],
              (newValue, oldValue) => routeDebounce(() => {
                // 一个问题 如果开了双向绑定 并且无参数启动页面 query要不要第一时间同步上去呢?还是说一次变更之后再进行url填充
                // 当前是第一次变更 如果需要立即回填删掉下面这行 并watch immediate
                if (isEqual(newValue, oldValue)) return;
                const queryPatch = { [id]: toQueryString(newValue, fields[id]) };
                // 代码中的undefined用来移除url
                if (newValue === undefined) {
                  queryPatch[id] = undefined;
                }
                // 去除冗余跳转
                if (renderer.$route.query[id] === queryPatch[id]) return;
                renderer.$router.replace({
                  query: { ...renderer.$route.query, ...queryPatch },
                  // Hack: 让 beforeRouteUpdate 守卫函数不刷新 UcRenderer
                  params: { block_reload_uc_renderer: true },
                }).catch(e => console.warn('[UICoreXiaoyaoPlugin]:query<->props', e));
              })
              , { deep: true },
            );
          });
        }
        // 解决完成 从temp1中移除
        temp1[id] = null;
      });
      // 既不在wcontext.data里也不在query里的 处理默认值
      forEach(temp1, (decl, id) => {
        if (!decl) return;  // overrided by wContext.data
        props[id] = cloneDeep((isDesignMode ? decl.example : void 0) ?? decl.default);
      });
      const isMainPagelet = renderer.ucPagelet.id === 'default';

      if (['modal', 'pagelet'].includes(renderer.ucPagelet.xyType)) {
        renderer.addDataSource({
          id: 'props',
          schema: {
            title: renderer.ucPagelet.xyType === 'modal' ? '传给弹窗的参数' : '传给页面片的参数',
            'uc:bindable': false,
            type: 'object',
            fields: Object.values(fields).map(x => ({ ...x, 'uc:tag': x['xy:sync'] ? '可双向绑定' : null })),
            // 主页面会更少使用到props
            'uc:collapsed': isMainPagelet,
          },
          instance: Vue.observable(props),
        });
      }
      // 在addDataSource之后挂载watch
      watchCallbackArray.forEach(cb => cb());
    }

    renderer.$watch(() => renderer.wContext.data, addDataSourceNamedProps);
    if (isDesignMode) {
      renderer.$watch(() => renderer.ucPagelet.propsSchema, addDataSourceNamedProps, { deep: true });
    }

    addDataSourceNamedProps();
  }

  /**
   * 根据 pagelet.xySources 做数据载入的工作，以及准备控制器
   * @param {import('@tencent/ui-core/lib/renderer/CRenderer').CRenderer} renderer
   */
  initDataSources(renderer) {
    const pagelet = renderer.ucPagelet;
    const newSourceConfigs = keyBy(cloneDeep(pagelet?.xySources || []), 'id');
    if (this.renderer === renderer && isEqual(newSourceConfigs, this.sourceConfigs)) return;     // nothing changed

    this.renderer = renderer;

    const sourceKeys = Object.keys(newSourceConfigs);

    // 先准备 dataSchema、data

    this.dataSchema = {
      definitions: [],
      type: 'object',
      'uc:bindable': false,
      fields: sourceKeys.map(key => ({ id: key, type: 'any' })),
    };
    this.loadingSchema = sourceKeys.length;

    // 然后开始初始化 sources
    // 同时创建一个 rootData，使用 getter 和 setter 把同名字段绑定到数据源控制器上

    // (设计模式) 先销毁原来的，如果有的话
    forEach(this.sources, (controller) => {
      try {
        controller.$destroy();
      } catch { }
    });

    // 这个是可能是老的，不能实时取这个
    this.sourceConfigs = newSourceConfigs;
    this.sources = mapValues(this.sourceConfigs, sourceConfig => createSourceController(sourceConfig, this, renderer));

    const mixedRootData = {};
    forEach(this.sources, (controller, key) => {
      controller.$on('reset', () => {
        const prefix = `data.${key}.`;
        logger.info(`[UICoreXY] Reset source ${key}`);
        renderer.ucRootForm.forEachEditors((editor) => {
          const bindings = get(editor, ['$vnode', 'data', 'uc:layout', 'bindings']);
          if (!bindings || typeof editor.reset !== 'function') return;
          if (some(bindings, val => String(val).startsWith(prefix))) editor.reset();
        });
      });

      const dummyPlaceholder = Object.freeze({
        loading: true,
        isReady: false,
        waitForReady: () => controller.waitForReady(),
      });

      const descriptor = {
        configurable: true,
        enumerable: true,
        get() {
          return controller.data || dummyPlaceholder;
        },
        set(data) {
          // eslint-disable-next-line no-param-reassign
          controller.data = data;
        },
      };

      // 避免 Vue 异步更新间隙，UCRenderer 拿到旧的 this.data 从而无法调用 waitForReady 的问题
      Object.defineProperty(this.data, key, descriptor);
      Object.defineProperty(mixedRootData, key, descriptor);
    });

    this.data = mixedRootData;

    // 数据源初始化请求结束
    Promise.all(Object.values(this.sources).map(source => source.waitForReady())).then(() => {
      this.$emit(EVENT_DS_READY, renderer);
    });
  }

  getRootData() {
    return this.data;
  }

  getRootDataSchema() {
    return this.dataSchema;
  }
}
