import { getDataOrThrow } from '@utils/common';
import fetch from '@/utils/fetch';
import getXyHeaders from './getXyHeaders';
import { message } from 'ant-design-vue';
import { getBeaconGlobalApi } from './userInfo';
import { isObject, mapValues, memoize } from 'lodash';
import { reLogin } from '@components/re-login-modal';

/**
 * 智能网关会在http协议时，1min续期一次，使用'X-Requested-With'请求头造成打扰
 * 启动项shouldLoginExpireCheck为false时只针对https做登录态过期校验
 *
 */
const shouldLoginExpireCheck = window.GLOBAL_INFO?.shouldLoginExpireCheck || window.location.protocol === 'https:';

let reLoginDirecting = false;

/**
 * @param {string} url
 * @param {Object} [options={}] fetch options
 * @param {Object} [options.query] url参数
 * @param {string} [options.body]
 * @param {string} [options.cache]
 * @param {string} [options.method=GET]
 * @param {string} [options.mode=cors]
 * @param {string} [options.credentials=include]
 * @param {number} [options.timeout]
 * @param {Object} [options.headers={}]
 * @param {boolean} [showMessage=false] 是否展示请求错误
 * @param {number} [retury=0] 重试次数
 * @returns {Promise}
 */
const wujiFetch = (fetchUrl, options = {}, showMessage = false, retry = 0) => {
  const customHeaders = getXyHeaders();

  const {
    timeout = 10000,
    headers = {},
    query,
    ...rest
  } = options;

  const url = addUrlParams(fetchUrl, query);

  // 逐个调用监听的函数
  Object.values(subscriber).forEach((handler) => {
    handler({
      url,
      options,
      showMessage,
      retry,
    });
  });

  return fetch(
    url,
    {
      ...rest,
      headers: {
        ...headers,
        ...customHeaders,
        ...(shouldLoginExpireCheck ? {
          // 添加该请求头，智能网关会在登陆态失效时返回401
          'X-Requested-With': 'XMLHttpRequest',
        } : {}),
      },
    },
    timeout,
    showMessage,
  ).then(getDataOrThrow)
    .catch((err) => {
      // 云上灯塔版本
      const globalApi = getBeaconGlobalApi();
      if (globalApi?.cloud) {
        if (reLoginDirecting) return;
        reLoginDirecting = true;
        if (err?.code === 401) {
          const callbackUrl = globalApi.cloud.getRealLocation().href;
          globalApi.auth.login({ callbackUrl });
        } else if (err?.code === 20053) { // not buy
          globalApi.cloud.goNotPurchasedPage();
        } else if (err?.code === 20054) { // not active
          globalApi.cloud.goNotActivatedPage();
        } else {
          reLoginDirecting = false;
          message.error(`unknown error with code ${err?.code}`);
        }
        return;
      }
      // 登录态过期
      if (shouldLoginExpireCheck && err?.code === 401) {
        reLogin();
        return;
      }
      if (retry > 0) {
        return wujiFetch(fetchUrl, options, showMessage, retry - 1);
      }
      throw err;
    });
};


const defaultWUjiFetchConfig = { method: 'GET', timeout: 5000 };

const argsHash = (...args) => JSON.stringify(args);

/**
 * 和 wujiFetch 一样，具备未登陆时的检查、错误toast等
 *
 * 相比旧的 `memoizedFetchGET` 而言功能更全
 */
export const memoizedFetchV2 = memoize((...args) => wujiFetch(...args).then((res) => {
  // 无数据则不需要缓存
  setTimeout(() => memoizedFetchV2.cache.delete(argsHash(...args)), !res ? 0 : 2000);
  return res;
}, (err) => {
  memoizedFetchV2.cache.delete(argsHash(...args));
  throw err;
}), argsHash);

export const memoizedFetchGET = memoize((url) => {
  setTimeout(() => {
    memoizedFetchGET.cache.delete(url);
  }, 10000);

  // 如果是请求localhost，不要加cookie，会触发新版本chrome安全问题
  const urlHost = url.replace(/^https*:\/\//, '').replace(/^\/\//, '');
  const isLocalFetch = urlHost.startsWith('localhost') || urlHost.startsWith('127.0.0.1');
  // 失败的话需要重试1次
  return wujiFetch(url, {
    ...defaultWUjiFetchConfig,
    ...(isLocalFetch ? {
      credentials: 'omit',
    } : null),
  }, false, 1);
});

export default wujiFetch;
const subscriber = {};

// 当有请求被发起时，调用注册的函数
// 注意：注册异步函数不会阻塞后续请求
export const subscribe = (handler, key) => {
  subscriber[key] = handler;
};

export const unsubscribe = (key) => {
  delete subscriber[key];
};


export const addUrlParams = (url, params) => {
  if (!params || Object.keys(params).length <= 0) {
    return url;
  }
  const paramValues = mapValues(params, (item) => {
    if (item === undefined || item === null) {
      return '';
    }
    if (isObject(item) || Array.isArray(item)) {
      return JSON.stringify(item);
    }
    return item;
  });

  const urlParams = new URLSearchParams(paramValues);
  return `${url}${url.includes('?') ? '&' : '?'}${urlParams.toString()}`;
};
