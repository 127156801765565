import { openMultiConflictModal } from '@components/merge-multi/index';
import { merge } from 'node-diff3';
import { omit, pick, set, get } from 'lodash';
import day from 'dayjs';
import { transpileWithESBuild } from '@/components/esbuild';
export const OMIT_COMMON_FIELDS = [
    '_ctime',
    'creator',
    '_mtime',
    '_id',
    'modifier',
    'ctime',
    'mtime',
];
// 判断远超的项目是否有修改(因为页面更新也会更新项目的 mtime, 所以不能通过 mtime 判断)
export const isRemoteProjectChange = ({ base, remote }) => {
    const baseProject = omit(base, OMIT_COMMON_FIELDS);
    const remoteProject = omit(remote, OMIT_COMMON_FIELDS);
    return JSON.stringify(baseProject) !== JSON.stringify(remoteProject);
};
// 获取冲突解决 or 合并结果
const getConflictResult = async (source, remote) => {
    const hasConflict = source.some(item => item.conflict === true);
    if (hasConflict) {
        return await openMultiConflictModal({
            source,
            title: `与 ${remote.modifier} 于 ${day(remote.mtime).format('YYYY-MM-DD HH:mm:ss')} 的修改合并存在冲突, 请手动解决冲突后提交合并`,
        });
    }
    return {
        success: true,
        data: source,
    };
};
export const mergeProject = async ({ local, base, remote }) => {
    const localKeys = Object.keys(omit(local, OMIT_COMMON_FIELDS));
    const pickLocal = pick(local, localKeys);
    const pickBase = pick(base, localKeys);
    const pickRemote = pick(remote, localKeys);
    const source = [];
    const independentKeys = ['uiInfo', 'advanceConfig', 'lessCodeModules'];
    // 应用配置
    if (localKeys.filter(key => independentKeys.includes(key)).length > 0) {
        const localInfo = omit(pickLocal, independentKeys);
        const baseInfo = omit(pickBase, independentKeys);
        const remoteInfo = omit(pickRemote, independentKeys);
        const infoResult = nodeDiff3Object({
            local: localInfo,
            base: baseInfo,
            remote: remoteInfo,
            type: 'json',
        });
        source.push({
            path: '',
            title: '应用配置',
            conflict: infoResult.conflict,
            result: infoResult.result,
            local: localInfo,
            type: 'json',
        });
    }
    // 导航和菜单
    if (pickLocal.uiInfo) {
        const localUI = omit(pickLocal.uiInfo, OMIT_COMMON_FIELDS);
        const baseUI = omit(pickBase.uiInfo, OMIT_COMMON_FIELDS);
        const remoteUI = omit(pickRemote.uiInfo, OMIT_COMMON_FIELDS);
        const uiResult = nodeDiff3Object({
            local: localUI,
            base: baseUI,
            remote: remoteUI,
            type: 'json',
        });
        source.push({
            path: 'uiInfo',
            title: '导航和菜单',
            conflict: uiResult.conflict,
            result: uiResult.result,
            local: localUI,
            type: 'json',
        });
    }
    // 扩展配置
    if (pickLocal.advanceConfig) {
        const omitAdvanceConfigKeys = ['lessCode', 'routeGuard', 'headScript', 'bodyScript'];
        const configResult = nodeDiff3Object({
            local: omit(pickLocal.advanceConfig, omitAdvanceConfigKeys),
            base: omit(pickBase.advanceConfig, omitAdvanceConfigKeys),
            remote: omit(pickRemote.advanceConfig, omitAdvanceConfigKeys),
            type: 'json',
        });
        source.push({
            path: 'advanceConfig',
            title: '扩展配置',
            conflict: configResult.conflict,
            result: configResult.result,
            local: omit(pickLocal.advanceConfig, omitAdvanceConfigKeys),
            type: 'json',
        });
        // 全局 LessCode
        const lessCodeResult = nodeDiff3String({
            local: pickLocal.advanceConfig.lessCode ?? '',
            base: pickBase?.advanceConfig?.lessCode ?? '',
            remote: pickRemote?.advanceConfig?.lessCode ?? '',
            type: 'javascript',
        });
        source.push({
            path: 'advanceConfig.lessCode',
            title: '全局 LessCode',
            conflict: lessCodeResult.conflict,
            result: lessCodeResult.result,
            local: pickLocal.advanceConfig.lessCode,
            type: 'javascript',
        });
        // 全局路由守卫
        const routeResult = nodeDiff3String({
            local: pickLocal.advanceConfig.routeGuard ?? '',
            base: pickBase?.advanceConfig?.routeGuard ?? '',
            remote: pickRemote?.advanceConfig?.routeGuard ?? '',
            type: 'javascript',
        });
        source.push({
            path: 'advanceConfig.routeGuard',
            title: '全局路由守卫',
            conflict: routeResult.conflict,
            result: routeResult.result,
            local: pickLocal.advanceConfig.routeGuard,
            type: 'javascript',
        });
        // Head 脚本
        const headResult = nodeDiff3String({
            local: pickLocal.advanceConfig.headScript ?? '',
            base: pickBase?.advanceConfig?.headScript ?? '',
            remote: pickRemote?.advanceConfig?.headScript ?? '',
            type: 'html',
        });
        source.push({
            path: 'advanceConfig.headScript',
            title: 'Head 脚本',
            conflict: headResult.conflict,
            result: headResult.result,
            local: pickLocal.advanceConfig.headScript,
            type: 'html',
        });
        // Body 脚本
        const bodyResult = nodeDiff3String({
            local: pickLocal.advanceConfig.bodyScript ?? '',
            base: pickBase?.advanceConfig?.bodyScript ?? '',
            remote: pickRemote?.advanceConfig?.bodyScript ?? '',
            type: 'html',
        });
        source.push({
            path: 'advanceConfig.bodyScript',
            title: 'Body 脚本',
            conflict: bodyResult.conflict,
            result: bodyResult.result,
            local: pickLocal.advanceConfig.bodyScript,
            type: 'html',
        });
    }
    const project = {};
    // 全局模块
    if (pickLocal.lessCodeModules) {
        const localFiles = Object.keys(pickLocal.lessCodeModules ?? {});
        const baseFiles = Object.keys(pickBase?.lessCodeModules ?? {});
        const remoteFiles = Object.keys(pickRemote?.lessCodeModules ?? {});
        const remoteAddFiles = remoteFiles.filter(file => !baseFiles.includes(file));
        localFiles.forEach((file) => {
            const path = ['lessCodeModules', file];
            set(project, path, get(pickLocal, path));
        });
        remoteAddFiles.forEach((file) => {
            const path = ['lessCodeModules', file];
            set(project, path, get(pickRemote, path));
        });
        localFiles.forEach((key) => {
            let hasSource = false;
            const getCode = (x) => {
                if (x?.source) {
                    hasSource = true;
                    return x.source;
                }
                return x?.code ?? '';
            };
            const moduleResult = nodeDiff3String({
                local: getCode(pickLocal?.lessCodeModules?.[key]),
                base: getCode(pickBase?.lessCodeModules?.[key]),
                remote: getCode(pickRemote?.lessCodeModules?.[key]),
                type: 'javascript',
            });
            source.push({
                path: ['lessCodeModules', key, hasSource ? 'source' : 'code'],
                title: `项目模块(${key})`,
                conflict: moduleResult.conflict,
                result: moduleResult.result,
                local: getCode(pickLocal?.lessCodeModules?.[key]),
                type: 'javascript',
            });
        });
    }
    const conflictRes = await getConflictResult(source, remote);
    const { success, data } = conflictRes;
    if (success) {
        for (const item of (data || [])) {
            let { result } = item;
            if (item.type === 'json' && typeof result === 'string')
                result = JSON.parse(result);
            if (item.path) {
                // LessCode 代码需要 transpile 处理
                if (item.path[0] === 'lessCodeModules' && item.path[2] === 'source') {
                    const modFileName = item.path[1];
                    const transpiled = await transpileWithESBuild(String(result), modFileName);
                    set(project, ['lessCodeModules', modFileName, 'code'], transpiled.code);
                }
                set(project, item.path, result);
            }
            else {
                Object.assign(project, result);
            }
        }
    }
    return {
        success,
        data: project,
    };
};
export const mergePage = async ({ local, base, remote }) => {
    const source = [];
    // 页面信息
    const omitKeys = [...OMIT_COMMON_FIELDS, 'pageConfig'];
    const infoResult = nodeDiff3Object({
        local: omit(local, omitKeys),
        base: omit(base, omitKeys),
        remote: omit(remote, omitKeys),
        type: 'json',
    });
    source.push({
        path: '',
        title: '页面信息',
        conflict: infoResult.conflict,
        result: infoResult.result,
        local: omit(local, omitKeys),
        type: 'json',
    });
    const localPageletKeys = local?.pageConfig?.map(item => item.id);
    const basePageletKeys = base?.pageConfig?.map(item => item.id);
    const remotePageletKeys = remote?.pageConfig?.map(item => item.id);
    // 本地增删的页面片
    const localAddKeys = localPageletKeys.filter(key => !basePageletKeys.includes(key));
    const localDeleteKeys = basePageletKeys.filter(key => !localPageletKeys.includes(key));
    // 合并后的页面片 id 集合
    const mergedKeys = [...new Set([...remotePageletKeys, ...localAddKeys])]
        .filter(key => !localDeleteKeys.includes(key));
    mergedKeys.forEach((key, index) => {
        const localPagelet = local?.pageConfig?.find(pagelet => pagelet.id === key);
        const basePagelet = base?.pageConfig?.find(pagelet => pagelet.id === key);
        const remotePagelet = remote?.pageConfig?.find(pagelet => pagelet.id === key);
        if (localPagelet && basePagelet && remotePagelet) {
            // 页面片布局
            const layoutResult = nodeDiff3Object({
                local: omit(localPagelet, ['lessCode']),
                base: omit(basePagelet, ['lessCode']),
                remote: omit(remotePagelet, ['lessCode']),
                type: 'json',
            });
            source.push({
                path: `pageConfig.${index}`,
                title: `${localPagelet.name} 布局`,
                conflict: layoutResult.conflict,
                result: layoutResult.result,
                local: omit(localPagelet, ['lessCode']),
                type: 'json',
            });
            // 页面片LessCode
            const lesscodeResult = nodeDiff3String({
                local: localPagelet.lessCode ?? '',
                base: basePagelet.lessCode ?? '',
                remote: remotePagelet.lessCode ?? '',
                type: 'javascript',
            });
            source.push({
                path: `pageConfig.${index}.lessCode`,
                title: `${localPagelet.name} LessCode`,
                conflict: lesscodeResult.conflict,
                result: lesscodeResult.result,
                local: localPagelet.lessCode ?? '',
                type: 'javascript',
            });
        }
        else if (localPagelet) {
            source.push({
                path: `pageConfig.${index}`,
                title: `${localPagelet.name} 布局`,
                conflict: false,
                result: omit(localPagelet, ['lessCode']),
                type: 'json',
            });
            source.push({
                path: `pageConfig.${index}.lessCode`,
                title: `${localPagelet.name} LessCode`,
                conflict: false,
                result: localPagelet.lessCode ?? '',
                type: 'javascript',
            });
        }
        else if (remotePagelet) {
            source.push({
                path: `pageConfig.${index}`,
                title: `${remotePagelet.name} 布局`,
                conflict: false,
                result: omit(remotePagelet, ['lessCode']),
                type: 'json',
            });
            source.push({
                path: `pageConfig.${index}.lessCode`,
                title: `${remotePagelet.name} LessCode`,
                conflict: false,
                result: remotePagelet.lessCode ?? '',
                type: 'javascript',
            });
        }
    });
    const hasConflict = source.some(item => item.conflict === true);
    let conflictRes;
    if (hasConflict) {
        conflictRes = await openMultiConflictModal({
            source,
            title: `与 ${remote.modifier} 于 ${day(remote.mtime).format('YYYY-MM-DD HH:mm:ss')} 的修改合并存在冲突, 请手动解决冲突后提交合并`,
        });
    }
    else {
        conflictRes = {
            success: true,
            data: source,
        };
    }
    const { success, data } = conflictRes;
    const page = {};
    if (success) {
        data?.forEach((item) => {
            let { result } = item;
            if (item.type === 'json' && typeof result === 'string')
                result = JSON.parse(result);
            if (item.path) {
                set(page, item.path, result);
            }
            else {
                Object.assign(page, result);
            }
        });
    }
    return {
        success,
        data: page,
    };
};
/**
 * 三路合并json对象
 */
export const nodeDiff3Object = (input) => {
    if (!input)
        throw new Error('缺少三路合并的必要参数');
    const { local, base, remote, type = 'json' } = input;
    return nodeDiff3String({
        local: JSON.stringify(local, null, 2),
        base: JSON.stringify(base, null, 2),
        remote: JSON.stringify(remote, null, 2),
        type,
    });
};
/**
 * 三路合并字符串
 */
export const nodeDiff3String = (input, { returnConflict = false } = {}) => {
    if (!input)
        throw new Error('缺少三路合并的必要参数');
    const { local, base, remote, type = 'json', localLabel, remoteLabel } = input;
    const format = (text) => text?.split('\n');
    const result = merge(format(local), format(base), format(remote), {
        stringSeparator: /[\r\n]+/,
    });
    if (result.conflict) {
        result.result = result.result.map((item) => {
            if (item === '<<<<<<<') {
                return `<<<<<<< ${localLabel || '当前变更'}`;
            }
            if (item === '>>>>>>>') {
                return `>>>>>>> ${remoteLabel || '最新变更'}`;
            }
            return item;
        });
    }
    else {
        result.result = type === 'json' ? JSON.parse(result.result.join('\n')) : result.result.join('\n');
    }
    if (returnConflict) {
        return {
            conflict: result.conflict,
            result: result.result,
        };
    }
    return result;
};
export const asyncNodeDiff3String = async (input, { returnConflict = false } = {}) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const DiffWorker = await import('./diff.worker').then((X) => X.default);
    const worker = new DiffWorker();
    return new Promise((resolve, reject) => {
        if (!input)
            throw new Error('缺少三路合并的必要参数');
        worker.addEventListener('message', (event) => {
            resolve(event.data);
            worker.terminate();
        });
        worker.addEventListener('error', (event) => {
            reject(event);
            worker.terminate();
        });
        setTimeout(() => {
            worker.postMessage({ input, returnConflict });
        }, 300);
    });
};
