import entity from './entity';
import workflow from './workflow';
import permission from './permission';
import staff from './staff';
import organization from './organization';
import robot from './robot';
import flow from './flow';
import email from './email';
import page from './page';
import collection from './collection';
import version from './version';

export const workflowApi = workflow;
export const workflowEntityApi = entity;
export const permissionApi = permission;
export const staffApi = staff;
export const organizationApi = organization;
export const robotApi = robot;
export const flowApi = flow;
export const emailSettingApi = email;
export const pageApi = page;
export const collectionApi = collection;
export const versionApi = version;

// 对用户开放的接口，如在页面中使用 `ctx.sdk.entityApi.fetch`
export const getExportableToCtxSdk = () => ({
  workflowApi,
  workflowEntityApi,
  permissionApi,
  staffApi,
});
