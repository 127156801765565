import Vue from 'vue';
import { ref } from '@vue/composition-api';
import { message } from 'ant-design-vue';
import { cloneDeep, defaultsDeep } from 'lodash';
import wujiFetch from '@utils/wujiFetch';
import { BASE_API_PATH } from '@/config/constant';
import { workflowApi } from '@/services';
import { getDefaultProjectSettings, getDefaultPublishHookRobotMsg } from '@loaders/project/utils';
import { useStore } from '@/store';
import { useRouter } from '@/router/useRouter';
import { ConfirmModal } from '@components/confirmModal/ConfirmModal';
const versionUrl = `${BASE_API_PATH}/xy/design/env/dev/version`;
export const fetchPublishSetting = async (projectId) => {
    const store = useStore();
    const data = await store.dispatch('fetchProjectSettings', {
        projectId,
    });
    return cloneDeep(data);
};
export const fetchRuntimePermissionSetting = async (projectId) => {
    const store = useStore();
    const data = await store.dispatch('fetchRuntimePermissionSettings', {
        projectId,
    });
    return cloneDeep(data);
};
export default function useProjectSetting({ projectId }) {
    const store = useStore();
    const projectSetting = ref(getDefaultProjectSettings());
    const baseProjectSetting = ref(getDefaultProjectSettings());
    const customWorkflowConfig = ref(null);
    const updating = ref(false);
    const initLoading = ref(false);
    const preEnabledBranch = ref(false);
    const fetchProjectSetting = async () => {
        try {
            const data = await fetchPublishSetting(projectId.value);
            const defaultSetting = getDefaultProjectSettings();
            if (data) {
                projectSetting.value = defaultsDeep(data, defaultSetting);
                preEnabledBranch.value = data.versionSetting.enabledBranch;
                baseProjectSetting.value = cloneDeep(projectSetting.value);
                if (!projectSetting.value.versionSetting.enablePublishHook
                    && !projectSetting.value.versionSetting.publishHookSetting.value.data) {
                    projectSetting.value.versionSetting.publishHookSetting.value.data = getDefaultPublishHookRobotMsg();
                }
            }
            if (!projectSetting.value.branchSetting)
                projectSetting.value.branchSetting = defaultSetting.branchSetting;
        }
        catch (err) {
            message.error(`获取应用设置失败:${err.message}`);
        }
    };
    const saveProjectSetting = async (data) => {
        try {
            updating.value = true;
            await store.dispatch('saveProjectSettings', {
                projectId: projectId.value,
                data,
            });
            message.success('保存成功');
            baseProjectSetting.value = cloneDeep(data);
            const currentEnabledBranch = data.versionSetting.enabledBranch;
            if (currentEnabledBranch !== preEnabledBranch.value) {
                // 切换分支功能开启保存后, 跳转应用配置首页
                setTimeout(() => {
                    const { router, route } = useRouter();
                    const query = route.value?.query ?? {};
                    if (currentEnabledBranch) {
                        // 如果是开启多分支功能, 则默认跳转 master 分支
                        query.branch = 'master';
                    }
                    else {
                        delete query.branch;
                    }
                    window.location.href = (router.value?.resolve({
                        path: '/project/config/pagelist',
                        query,
                    }).href);
                }, 500);
            }
        }
        catch (err) {
            message.error(`保存失败:${err.message}`);
        }
        finally {
            updating.value = false;
        }
    };
    const confirmSaveProjectSetting = async () => {
        if (JSON.stringify(projectSetting.value) !== JSON.stringify(baseProjectSetting.value)) {
            return await new Promise((rs) => {
                const instance = new Vue({
                    render: h => h(ConfirmModal, {
                        on: {
                            close: (isSave) => {
                                rs(isSave);
                                instance.$destroy();
                                instance.$el?.parentNode?.removeChild(instance.$el);
                            },
                        },
                    }),
                });
                const el = document.createElement('div');
                document.body.appendChild(el);
                instance.$mount();
            });
        }
        return false;
    };
    const fetchCustomWorkflow = async () => {
        const workflowId = `${projectId.value}:general-release-approval`;
        const flowConfig = await workflowApi.fetchWorkflow(projectId.value, 'dev', workflowId);
        customWorkflowConfig.value = flowConfig;
    };
    const initApprovalWorkflow = async () => {
        const flowConfig = await wujiFetch(`${versionUrl}/project/approval/init?projectid=${projectId.value}`, {
            method: 'POST',
        });
        return flowConfig;
    };
    const initCustomWorkflow = async () => {
        initLoading.value = true;
        try {
            const flowConfig = await initApprovalWorkflow();
            customWorkflowConfig.value = flowConfig;
            message.success('生成发版审批流程成功');
        }
        catch (error) {
            message.error('生成发版审批流程失败');
        }
        initLoading.value = false;
    };
    return {
        fetchProjectSetting,
        fetchCustomWorkflow,
        initCustomWorkflow,
        saveProjectSetting,
        confirmSaveProjectSetting,
        projectSetting,
        customWorkflowConfig,
        updating,
    };
}
