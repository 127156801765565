import { inject } from '@vue/composition-api';
import Vue from 'vue';
export const MODAL_CONTEXT_KEY = Symbol('modal_context');
export function injectModalContext() {
    const ctx = inject(MODAL_CONTEXT_KEY);
    return ctx;
}
;
export default function modalFactory(Comp, initOptions = {}) {
    return async function openModal(options) {
        let resolve;
        const promise = new Promise(r => (resolve = r));
        const ctx = {
            resolve: (result) => {
                resolve(result);
                instance.$destroy();
            },
            success: (data) => {
                const result = {
                    success: true,
                    message: 'success',
                    data,
                };
                resolve(result);
                instance.$destroy();
            },
            cancel: () => {
                const result = {
                    success: false,
                    message: 'cancel',
                };
                resolve(result);
                instance.$destroy();
            },
        };
        const instance = new Vue({
            parent: initOptions.parent?.(),
            provide: {
                [MODAL_CONTEXT_KEY]: ctx,
            },
            methods: {
                onMessage(data) {
                    resolve(data);
                    this.$destroy();
                },
            },
            render() {
                return <Comp options={options} onMessage={this.onMessage}></Comp>;
            },
        });
        const el = document.createElement('div');
        document.body.appendChild(el);
        instance.$mount(el);
        return promise;
    };
}
