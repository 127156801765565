<template>
  <div
    v-if="tagList.length"
    class="tag-view"
  >
    <a-tag
      v-if="tagList.length > 1"
      class="tag-link"
      @click="handlerRemoveOthers"
    >
      关闭其他
    </a-tag>
    <div
      ref="scrollContainer"
      class="tag-contain"
    >
      <router-link
        v-for="tag in tagList"
        :key="tag.path"
        :to="tag.path"
        tag="span"
      >
        <a-tag
          :class="[`tag-link`, { 'tag-link-active': $route.fullPath === tag.path }]"
          :closable="$route.fullPath !== tag.path"
          @close="removeTag(tag)"
        >
          {{ tag.name }}
        </a-tag>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      tagList: state => state.navigation.tagList,
      showMenu: state => state.navigation.showMenu,
      breadcrumb: state => state.navigation.breadcrumb,
      pageDetail: state => state.runtime.currentPage,
    }),
  },
  mounted() {
    this.$watch(
      () => ({ route: this.$route, tagList: this.tagList }),
      () => this.$nextTick(this.updateActiveTag),
      {
        deep: true,
        immediate: true,
      },
    );
    this.$watch(
      () => this.$route,
      this.addTag,
      {
        deep: true,
        immediate: true,
      },
    );
  },
  methods: {
    ...mapActions('navigation', ['removeTag', 'reserveTag', 'cleanTag']),
    // 关闭其他标签
    handlerRemoveOthers() {
      const findIndex = this.tagList.findIndex(item => item.path === this.$route.fullPath);
      if (findIndex > -1) {
        this.reserveTag(findIndex);
      } else {
        this.cleanTag();
      }
    },
    // 默认是菜单栏更新标签，因为菜单栏有name，当菜单栏没有命中时，需要自行添加标签
    addTag() {
      // 不存在菜单栏 或者 没有命中菜单栏
      if (!this.showMenu || !this.breadcrumb.length) {
        setTimeout(() => {
          const name = this.pageDetail?.name;
          if (name) {
            this.$store.dispatch('navigation/addTag', { name, path: this.$route.fullPath });
            this.$store?.dispatch('navigation/setBreadcrumb', [name]);
          }
        }, 50);
      }
    },
    // 更新激活标签的位置
    updateActiveTag() {
      const tagContainer = this.$refs.scrollContainer;
      const activeTag = tagContainer?.querySelector('.tag-link-active');
      if (activeTag) {
        // 激活标签在滚动的左边
        if (tagContainer.scrollLeft > activeTag.offsetLeft) {
          tagContainer.scrollLeft = activeTag.offsetLeft;
        } else if (activeTag.offsetLeft + activeTag.offsetWidth > tagContainer.scrollLeft + tagContainer.offsetWidth) {
          // 激活标签在滚动的右边
          tagContainer.scrollLeft = activeTag.offsetLeft + activeTag.offsetWidth - tagContainer.offsetWidth;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-view {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
}
.tag-contain {
  flex: 1;
  white-space: nowrap;
  overflow: scroll;
  position: relative;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.tag-link {
  cursor: pointer;
  padding: 5px 10px;
  background: white;
  border-color: #e5e5e5;
  &-active {
    color: var(--wuji-primary-color);;
  }
}
</style>
