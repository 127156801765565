import Vue from 'vue';
import { debounce, throttle } from 'lodash';
import { message } from 'ant-design-vue';
import { defineComponent, onMounted, onUnmounted, computed } from '@vue/composition-api';
import cookies from 'js-cookie';
import { BASE_PATH } from '@/config/constant';
import { getLogoutURL } from '@utils/userInfo';
import wujiFetch from '@/utils/wujiFetch';
import { BASE_API_PATH } from '@config/constant';
import styles from './index.module.scss';
import { isOffline } from '@/utils/offline';
let reLoginConfirming = false;
export const reLogin = () => {
    let warningMsg = '';
    const ssoReason = cookies.get('xy-sso-logout-reason');
    if (ssoReason === 'account has been logined on other devices') {
        warningMsg = '账号已经在其他设备登录';
    }
    if (reLoginConfirming)
        return;
    reLoginConfirming = true;
    const instance = new Vue({
        methods: {
            onClose() {
                reLoginConfirming = false;
                this.$destroy();
            },
        },
        render() {
            return (<ReLoginModal warningMsg={warningMsg} onClose={this.onClose}/>);
        },
    });
    const el = document.createElement('div');
    document.body.appendChild(el);
    instance.$mount(el);
};
// 请求接口判断是否有登录态
const detect = async () => {
    try {
        // 注意：wujiFetch 自己会调用 reLogin 显示弹窗
        const result = await wujiFetch(`${BASE_API_PATH}/xy/healthcheck`);
        return !!result;
    }
    catch (err) {
        return false; // server down
    }
};
const detectIfNeeded = debounce(() => {
    if (document.visibilityState !== 'visible')
        return;
    if (reLoginConfirming)
        return;
    detect();
}, 3000, { leading: true, trailing: false });
// when page got focus or visible, detect once.
setTimeout(() => {
    const initialUser = window.GLOBAL_INFO?.rtx;
    if (!initialUser || initialUser === 'guest' || isOffline())
        return;
    window.addEventListener('focus', detectIfNeeded, false);
    document.addEventListener('visibilitychange', detectIfNeeded, false);
}, 1000);
const MAX_COUNT = 150;
const ReLoginModal = defineComponent({
    name: 'ReLoginModal',
    props: {
        warningMsg: String,
    },
    setup(props, { emit }) {
        const handleClose = () => emit('close');
        const warnMsg = computed(() => props.warningMsg || '检测到登录态已过期，请重新登录');
        let count = 0;
        const detectAndClose = throttle(() => {
            if (document.visibilityState !== 'visible')
                return;
            detect().then((result) => {
                if (result) {
                    handleClose();
                    detectAndClose.cancel();
                }
                else {
                    count += 1;
                    if (count < MAX_COUNT)
                        setTimeout(detectAndClose, 1000);
                }
            });
        }, 1000, { leading: true, trailing: true });
        const handleDetect = async () => {
            const result = await detect();
            if (result) {
                handleClose();
            }
            else {
                message.warning('登录失败, 请点击重新登录');
            }
        };
        const handleReLogin = () => {
            // 跳转到新窗口，重新拉起登录...登录后新页面立刻关闭
            const loginPageUrl = `${window.location.origin}${BASE_PATH}login`;
            opener = window.open(getLogoutURL(loginPageUrl));
        };
        const handleLoginSuccess = (e) => {
            if (e?.data?.event === 'login')
                handleClose();
        };
        onMounted(() => {
            detectAndClose();
            window.addEventListener('focus', detectAndClose, false);
            window.addEventListener('message', handleLoginSuccess);
        });
        onUnmounted(() => {
            window.removeEventListener('focus', detectAndClose, false);
            window.removeEventListener('message', handleLoginSuccess);
        });
        return {
            handleDetect,
            handleReLogin,
            warnMsg,
        };
    },
    render() {
        return (<a-modal class={styles.reLoginModal} visible={true} centered={true} footer={null} closable={false}>
        <div class={styles.contentWrap}>
          <a-icon class={styles.contentIcon} type="exclamation-circle"/>
          <div>
            <div class={styles.contentTitle}>登录态已过期</div>
            <div class={styles.content}>{this.warnMsg}</div>
          </div>
        </div>
        <div class={styles.footer}>
          <a-space>
            <a-button onClick={this.handleDetect}>已登录</a-button>
            <a-button type="primary" onClick={this.handleReLogin}>重新登录</a-button>
          </a-space>
        </div>
      </a-modal>);
    },
});
