import fetch from 'isomorphic-fetch';
import { message } from 'ant-design-vue'; // ant-design-vue/es/index
import { BASE_PATH } from '../config/constant';
import store from '@store/index';
import { addPathPrefix } from '@utils/path';
import qs from 'query-string';
import stringInterop from '@tencent/ui-core/lib/utils/stringInterop';

export function runtimeInfo() {
  const { mode, project } = store.state.runtime;
  if (mode !== 'runtime') {
    return {};
  }

  const { app = {} } = store.state;
  const { projectId, env } = app;
  const { bizIsolation, page403 } = project ?? {};
  const pageId = store.getters['runtime/currentPageId'];
  const page403Path = page403.path || '/403';
  const page403Query = (page403.path ? page403.query : '') || '';
  return { mode, projectId, pageId, env, bizIsolation, page403Path, page403Query };
}

const isStartFlowJob = (url, options) => url.includes('/manage/flow/job') && options?.method?.toLowerCase() === 'post';
const get403Href = (page403Path, query = {}) => {
  const customAuth403path = window.GLOBAL_INFO?.deploySettingFlag.customAuth403path;
  if (customAuth403path) {
    const path = stringInterop(customAuth403path, { query });
    return path;
  }
  const router = window.xy_runtime_router;
  return router.resolve({ path: page403Path, query }).href;
};

const runtimeErrorHandler = (e, url, options) => {
  if (e.code === 403) {
    const { projectId, page403Path, page403Query, bizIsolation } = runtimeInfo();
    if (!!projectId) {
      if (!url.includes('/manage/page/') && !isStartFlowJob(url, options)) {
        message.error('无权调用');
        return;
      }
      e.response.json()
        .then(({ data }) => {
          const query = {
            envId: data?.env ?? '',
            pageId: data?.pageId ?? '',
            pagePath: data?.pagePath ?? '',
            requestPath: data?.requestPath ?? '',
            permission: data?.permission ?? '',
            ...(bizIsolation ? { [bizIsolation]: data?.bizId ?? '' } : {}),
            api: data?.api ?? '',
            method: data?.method ?? '',
            url: String(location.href),
            ...qs.parse(page403Query),
          };
          if (isStartFlowJob(url, options)) {
            try {
              const body = JSON.parse(options.body);
              query.flowId = body.flowId;
            } catch (error) {
              // ignore
            }
            const href403 = get403Href(page403Path, query);
            message.error(h => h('span', null, [
              '无权发起流程，点击',
              h('a', {
                attrs: {
                  href: href403,
                  target: '_blank',
                },
              }, '申请权限'),
            ]));
            return;
          };
          const customAuth403path = window.GLOBAL_INFO?.deploySettingFlag.customAuth403path;
          if (customAuth403path) {
            const path = stringInterop(customAuth403path, { query });
            window.location = path;
            return;
          }
          const router = window.xy_runtime_router;
          if (router.currentRoute.path !== page403Path) {
            router.push({ path: page403Path, query });
          }
        })
        .catch(err => console.error('error when dealing 403:', err));
    }
  }
};

const designModeErrorHandler = async (e, url, options) => {
  // 获取应用信息返回 403 时
  if (e.code === 403 && url.includes('/manage/project/')) {
    // 当调用的事get接口的时候，跳转到403页
    if (!options.method || options.method === 'GET') {
      window.onbeforeunload = function () { }; // 取消beforeunload事件导致跳转被阻止
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (!location.pathname.includes('/403')) {
        location.href = `${BASE_PATH}403${params.projectid ? `?projectid=${params.projectid}` : ''}`;
      }
    }
    if (`${store.state.project?.projectInfo?.visibility}` === '1') {
      return message.warning('公开应用不允许修改数据，只允许读取数据');
    }
  }
};

async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  let errorText = '';
  try {
    // 403 需要特殊处理，response.json 只能读一次，留给特殊处理时读取
    const data = response.status === 403 ? {} : await response.json();
    errorText = data.error || data.msg || response.statusText;
  } catch (e) {
    errorText = response.statusText;
  }

  const error = new Error(errorText);
  error.code = response.status;
  error.response = response;

  throw error;
}

function parseJSON(response) {
  return response.json();
}

const wrapFetch = function (url, options, timeout = 60000, showMessage = true, parseData = true) {
  let abortTimer;
  const abortPromise = new Promise((resolve, reject) => {
    abortTimer = setTimeout(() => {
      const error = new Error('请求超时');
      error.code = -101;
      reject(error);
    }, timeout);
  });

  let targetUrl = url;

  // 将没有host的请求添加host
  if (!/^(http:|https:|\/\/)/.test(url)) {
    targetUrl = `//${location.host}${addPathPrefix(url)}`;
  }

  const fetchPromise = fetch(targetUrl, {
    mode: 'cors',
    credentials: 'include',
    ...options,
  });
  const allPromise = Promise.race([fetchPromise, abortPromise]);

  return allPromise
    .then((data) => {
      clearTimeout(abortTimer);
      return data;
    })
    .then(checkStatus)
    .then(parseJSON)
    .then((data) => {
      if (!parseData) {
        return data;
      }
      if (data.code !== 0 && data.code !== 200 && data.iErrCode !== 0) {
        const error = new Error(data.msg || data.error);
        error.code = data.code;
        error.data = data.data;
        throw error;
      } else {
        return data;
      }
    })
    .catch((e) => {
      clearTimeout(abortTimer);
      if (showMessage) message.error(`请求失败: code=${e.code}, msg=${e.message}`, 3);
      const { mode } = store.state.runtime;
      if (mode === 'runtime') {
        runtimeErrorHandler(e, url, options);
      } else {
        designModeErrorHandler(e, url, options);
      }
      throw e;
    });
};

export const request = ({
  path,
  method,
  data,
}) => wrapFetch(path, {
  method,
  body: data,
});

export default wrapFetch;
