<template>
  <page-layout
    :wrap-styles="{
      padding: '24px',
    }"
    :styles="{
      padding: '0px',
    }"
  >
    <div v-if="isPermissionApply && (isMobileScreen ? entity.flowStatus === 'processing' : true)">
      <div
        v-if="!isMobileScreen"
        style="display:grid;grid-template-columns:1fr 1fr;gap:0 12px"
      >
        <apply-approval
          :project-id="projectId"
          :env="env"
          :entity="entity"
        />
        <w-sys-page
          :config="getPageConfig()"
          :project-config="projectConfig"
          style="background-color:transparent"
        />
      </div>
      <div v-else>
        <apply-approval
          :project-id="projectId"
          :env="env"
          :entity="entity"
        />
      </div>
    </div>
    <w-sys-page
      v-else
      :config="getPageConfig()"
      :project-config="projectConfig"
      style="background-color:transparent"
    />
  </page-layout>
</template>

<script>
import { isMobileOrNarrowScreen } from '@utils/screen';
import { APPLY_WORKFLOW_ID } from '@pages/modules/permission/constants';
import ApplyApproval from '../../permission/ApplyApproval.vue';
import { BUILTIN_PAGE_ID } from '@config/constant';

export default {
  components: {
    ApplyApproval,
  },
  props: {
    projectId: {
      type: String,
      default: '',
    },
    env: {
      type: String,
      default: '',
    },
    entity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      projectConfig: window.xy_runtime_project,
    };
  },
  computed: {
    isPermissionApply() {
      return this.checkIsPermissionApply();
    },
    payload() {
      if (!this.entity) return null;
      const { payload } = this.entity;
      return JSON.parse(payload);
    },
    isMobileScreen() {
      return isMobileOrNarrowScreen();
    },
  },
  methods: {
    checkIsPermissionApply() {
      if (!this.entity) return false;
      const { flowId } = this.entity;
      return flowId.endsWith(APPLY_WORKFLOW_ID);
    },
    getPageConfig() {
      return {
        projectId: this.projectId,
        pageId: BUILTIN_PAGE_ID,
        title: '流程实例详情',
        pageData: {},
        pageConfig: [
          {
            id: 'default',
            name: 'mainPage',
            ucVersion: 1,
            xyType: 'pagelet',
            lessCode: 'export default {\n  data() {\n    return {\n    };\n  },\n  mounted(){\n  },\n  methods: {\n  },\n};\n',
            layout: {
              type: 'w-container',
              props: {},
              children: [
                {
                  type: 'w-row',
                  children: [
                    {
                      type: 'w-col',
                      children: [
                        {
                          type: 'w-card',
                          props: {
                            title: '流程详情',
                          },
                          children: [
                            {
                              type: 'public-w-workflow-detail',
                              props: {
                                hideTitle: true,
                                isNarrowScreen: isMobileOrNarrowScreen(),
                              },
                            },
                          ],
                        },
                        ...(!this.isPermissionApply ? [{
                          type: 'w-card',
                          children: [
                            {
                              type: 'public-w-workflow-approve',
                            },
                          ],
                          props: {
                            css: `width: ${isMobileOrNarrowScreen() ? '100%' : '50%'}`,
                          },
                        }] : []),
                      ],
                      props: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
