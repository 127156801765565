import { cloneDeep, set } from 'lodash';
import { getPatchedDataSchema } from '@tencent/data-schema-core';
import { schemaOfUtilsForUser } from '@tencent/wuji-utils';
import { getAvailableComponent } from '../comps-loader';
import { SSO_DEPLOY } from '@/config/constant';
import getFlowSchema from './flow-schema';
const getSchemaOfUtilsForUser = () => {
    const schema = cloneDeep(schemaOfUtilsForUser);
    schema.id = 'utils';
    // 过滤掉 Lodash 的方法
    schema.methods = schema.methods.filter((item) => ![
        'cloneDeep',
        'isEqual',
        'forEach',
        'fromPairs',
        'toPairs',
        'pick',
        'omit',
        'get',
        'mapValues',
        'orderBy',
        'filterData',
        'translateFilterToStr',
    ].includes(item.id));
    return getPatchedDataSchema(schema);
};
// 预设字段对应的组件
const COMP = {
    readonlyText: 'w-readonly',
    boolean: 'w-boolean',
    jsonText: 'w-json',
    avatar: 'w-avatar',
    image: 'w-image',
};
const fitSchemaFieldUIType = (schema) => {
    // 已经在 schema 定义 ui 的字段不做处理
    if (schema.ui)
        return schema;
    // 只读字符串
    if (schema.type === 'string' && schema.readonly) {
        set(schema, 'ui.type', getAvailableComponent('public', COMP.readonlyText));
    }
    // 布尔值
    if (schema.type === 'boolean') {
        set(schema, 'ui.type', getAvailableComponent('public', COMP.boolean));
        if (schema.readonly) {
            set(schema, 'ui.props.disabled', true);
        }
    }
    // 只读对象/数组
    if (schema.type && ['object', 'array'].includes(schema.type) && schema.readonly) {
        set(schema, 'ui.type', getAvailableComponent('public', COMP.jsonText));
        set(schema, 'ui.props.readonly', true);
    }
    if (schema.type === 'object' && Array.isArray(schema.fields)) {
        schema.fields.forEach(field => fitSchemaFieldUIType(field));
    }
    return schema;
};
const getNavigateArguments = () => [
    {
        id: 'option',
        type: 'object',
        description: 'id: 页面ID; path: 页面路径; query: 查询参数; params: 路由参数',
        fields: [
            {
                id: 'id',
                type: 'string',
            },
            {
                id: 'path',
                type: 'string',
            },
            {
                id: 'query',
                type: 'object',
            },
            {
                id: 'query',
                type: 'object',
            },
        ],
    },
];
const getPageInfoFields = () => [
    { id: 'pageId', title: '页面ID', type: 'string', readonly: true },
    { id: 'name', title: '页面名称', type: 'string', readonly: true },
    { id: 'title', title: '页面标题', type: 'string', readonly: true },
    { id: 'path', title: '页面路径', type: 'string', readonly: true },
];
const getStaffInternalFields = () => (window.GLOBAL_INFO.externalDeploy
    ? []
    : [
        { id: 'workDeptId', title: '部门ID', type: 'string', readonly: true, hidden: false },
        { id: 'workDeptIdString', title: '部门IDs(分号;分割)', type: 'string', readonly: true, hidden: false },
        { id: 'workOrgIdString', title: '中心IDs(分号;分割)', type: 'string', readonly: true, hidden: false },
        { id: 'positionName', title: '职位', type: 'string', readonly: true, hidden: false },
    ]);
export const getWDataSchema = () => Object.freeze(fitSchemaFieldUIType({
    id: 'w',
    title: '前端API',
    type: 'object',
    readonly: true,
    'uc:collapsed': true,
    'uc:bindable': false,
    methods: [
        // 弹窗
        {
            id: 'showModal',
            title: '打开弹窗',
            description: '打开当前页面的指定弹窗',
            isAsync: true,
            arguments: [
                { id: 'id', type: 'string', required: true, description: '需要打开的弹窗id' },
                { id: 'data', type: 'object', description: '打开弹窗的传参(可选)' },
            ],
        },
        {
            id: 'hideModal',
            title: '关闭弹窗',
            description: '关闭当前页面的指定弹窗',
            arguments: [
                { id: 'id', type: 'string', description: '需要关闭的弹窗id（可选, 当参数为空时表示关闭当前调用的弹窗）' },
                { id: 'data', type: 'object', description: '关闭弹窗的返回值(可选)' },
            ],
        },
        {
            id: 'showGlobalModal',
            title: '打开全局弹窗',
            description: '打开页面类型为弹窗的全局弹窗',
            isAsync: true,
            arguments: [
                { id: 'id', type: 'string', required: true, description: '需要打开的全局弹窗id(弹窗页面id)' },
                { id: 'data', type: 'object', description: '打开全局弹窗的传参' },
                { id: 'opt', type: 'object', description: '全局弹窗的配置项，keepAlive是否保留实例，suffix用来区分实例的后缀名' },
            ],
        },
        {
            id: 'hideGlobalModal',
            title: '关闭全局弹窗',
            description: '关闭页面类型为弹窗的全局弹窗',
            arguments: [
                { id: 'id', type: 'string', description: '需要关闭的"全局弹窗id_后缀suffix"("弹窗页面id__后缀suffix")' },
                { id: 'data', type: 'object', description: '关闭全局弹窗的返回值' },
                { id: 'opt', type: 'object', description: '关闭全局弹窗的配置项，destroy是否强制销毁实例' },
            ],
        },
        // HTTP 请求
        {
            id: 'request',
            title: '发起 HTTP 网络请求',
            description: '返回值为 json.parse 后的对象, 其他与 fetch API 一致',
            arguments: [
                { id: 'url', type: 'string', required: true, description: '请求资源地址' },
                { id: 'option', type: 'object', description: '请求参数' },
            ],
        },
        // 云函数
        {
            id: 'callFunc',
            title: '调用云函数',
            isAsync: true,
            arguments: [
                { id: 'funcName', type: 'string', required: true, description: '云函数id' },
                { id: 'axiosOptions', type: 'object', description: '透传的 axios 配置参数(可选)' },
            ],
        },
        // 数据源
        {
            id: 'requestCgi',
            title: '请求数据源接口',
            isAsync: true,
            arguments: [
                { id: 'collectionId', type: 'string', required: true, description: '数据源id' },
                { id: 'cgiId', type: 'string', required: true, description: '数据源的接口id' },
                {
                    id: 'params',
                    type: 'object',
                    description: '数据源的请求参数(可选)',
                    fields: [
                        {
                            id: 'query',
                            type: 'object',
                            description: 'url参数',
                        },
                        {
                            id: 'body',
                            type: 'object',
                            description: 'post参数',
                        },
                        {
                            id: 'headers',
                            type: 'object',
                            description: '请求头',
                        },
                        {
                            id: 'params',
                            type: 'object',
                            description: 'url路径参数，前端数据源特有',
                        },
                    ],
                },
            ],
        },
        {
            id: 'waitForDataSourceReady',
            title: '等待所有数据源就绪',
            description: '该方法能确保后续逻辑的数据源变量和方法的调用',
            isAsync: true,
            arguments: [],
        },
        // 公共参数
        {
            id: 'getCommonQueryValue',
            title: '根据 key 获取公共参数的值',
            arguments: [
                { id: 'key', type: 'string', required: true, description: '公共参数 key' },
            ],
        },
        {
            id: 'getCommonQueryOptions',
            title: '根据 key 获取公共参数的选项',
            arguments: [
                { id: 'key', type: 'string', required: true, description: '公共参数 key' },
            ],
        },
        {
            id: 'fetchCommonQueryOptions',
            title: '根据 key 重新请求公共参数的选项',
            isAsync: true,
            description: '请求完成后顶部导航左上角的公共参数组件选项也会更新',
            arguments: [
                { id: 'key', type: 'string', required: true, description: '公共参数 key' },
            ],
        },
        // 权限
        {
            id: 'hasPermission',
            title: '判断用户权限方法',
            description: '判断当前用户有没有自定义权限，如：w.hasPermission("custom-permission-id")',
            arguments: [
                { id: 'permission', type: 'string', required: true, description: '权限ID(例如 `"custom-permission-id"`)' },
                // 可选 pageId 参数，不用直接补全，因而注释
                // { id: 'pageId', type: 'string', description: '可选，指定需要校验的页面 id' },
            ],
        },
        // 路由
        {
            id: 'navigateTo',
            title: '跳转页面',
            description: '跳转到应用内的指定页面, 保留当前页面',
            arguments: getNavigateArguments(),
        },
        {
            id: 'redirectTo',
            title: '跳转页面',
            description: '跳转到应用内的指定页面, 关闭当前页面',
            arguments: getNavigateArguments(),
        },
        {
            id: 'openPage',
            title: '新窗口打开页面',
            description: '新窗口打开指定页面',
            arguments: getNavigateArguments(),
        },
        {
            id: 'getResolveHref',
            title: '获取解析的页面路径',
            arguments: getNavigateArguments(),
            returnValue: { type: 'string' },
        },
        // 滚动
        {
            id: 'scrollTo',
            title: '将页面滚动到目标位置',
            arguments: [
                {
                    id: 'option',
                    type: 'object',
                    description: '滚动参数, 具体请查看前端 API 文档',
                    fields: [
                        { id: 'top', type: 'number', default: 0 },
                        { id: 'behavior', type: 'string', enum: [{ value: 'instant' }, { value: 'smooth' }] },
                    ],
                },
            ],
        },
        // 确认框
        {
            id: 'confirm',
            title: '确认框',
            isAsync: true,
            arguments: [
                {
                    id: 'option',
                    type: 'object',
                    description: '确认框配置项',
                    fields: [
                        { id: 'title', type: 'string', required: true, description: '确认框标题' },
                        { id: 'content', type: 'string', description: '确认框内容' },
                        { id: 'okText', type: 'string', description: '确认按钮文本' },
                        { id: 'cancelText', type: 'string', description: '取消按钮文本' },
                        { id: 'onOk', type: 'any', description: '确认回调函数' },
                        { id: 'onCancel', type: 'any', description: '取消回调函数' },
                    ],
                },
            ],
        },
        {
            id: 'getCompInstancesById',
            title: '获取指定组件实例（返回值为 Vue 实例数组）',
            arguments: [
                { id: 'compId', type: 'string', required: true, description: '组件ID' },
            ],
            returnValue: {
                type: 'array',
                items: {
                    type: 'any',
                    tsType: 'Vue',
                    readonly: true,
                },
            },
        },
        {
            id: 'getRenderByCompInstance',
            title: '获取指定组件所属的页面片实例',
            arguments: [
                { id: 'comp', type: 'any', tsType: 'Vue', required: true, description: '组件实例' },
            ],
        },
        // 引入模块
        {
            id: 'require',
            title: '引入全局模块',
            arguments: [
                { id: 'path', type: 'string', required: true, description: '模块路径' },
            ],
        },
        // 上报
        {
            id: 'report',
            title: '上报',
            arguments: [
                {
                    id: 'data',
                    type: 'object',
                    required: true,
                    description: '上报数据',
                    fields: [
                        { id: 'event', type: 'string', required: true, description: '事件名称' },
                        { id: 'eid', type: 'string', required: true, description: '元素ID' },
                    ],
                },
            ],
        },
        {
            id: 'getAtoms',
            title: '获取行为事件',
            arguments: [
                { id: 'renderer', type: 'any', required: true, description: 'Renderer 实例' },
            ],
        },
    ],
    fields: [
        {
            id: '$app',
            title: '全局 LessCode 实例',
            type: 'any',
            readonly: true,
            'uc:bindable': false,
        },
        {
            id: 'renderer',
            title: '当前页面片实例',
            type: 'any',
            tsType: 'Vue',
            readonly: true,
            'uc:bindable': false,
        },
        {
            id: 'mainRenderer',
            title: '当前主页面的页面片实例',
            type: 'any',
            tsType: 'Vue',
            readonly: true,
            'uc:bindable': false,
        },
        { id: 'mode', title: '模式', type: 'string', readonly: true },
        { id: 'env', title: '当前环境类型', type: 'string', readonly: true },
        {
            id: 'user',
            title: '当前用户',
            type: 'object',
            readonly: true,
            'uc:collapsed': true,
            fields: [
                { id: 'userId', title: '用户ID', type: 'string', readonly: true },
                { id: 'name', title: '英文名', type: 'string', readonly: true },
                { id: 'chnName', title: '中文名', type: 'string', readonly: true },
                { id: 'deptName', title: '部门名', type: 'string', readonly: true },
                { id: 'deptNameString', title: '组织名(斜杠/分割)', type: 'string', readonly: true },
                { id: 'deptIdString', title: '组织IDs(分号;分割)', type: 'string', readonly: true },
                { id: 'staffId', title: '用户ID', type: 'string', readonly: true, hidden: true },
                { id: 'avatar', title: '头像图地址', type: 'string',
                    readonly: true,
                    ui: { type: getAvailableComponent('public', COMP.avatar) } },
                { id: 'editorRole', title: '编辑侧身份', type: 'string', readonly: true },
                { id: 'isAdmin', title: '是否管理员', type: 'boolean', readonly: true },
                { id: 'isLogin', title: '是否登录', type: 'boolean', readonly: true },
                ...getStaffInternalFields(),
                {
                    id: 'roles',
                    title: '所属角色列表',
                    type: 'array',
                    description: '预览模式下无',
                    readonly: true,
                    items: {
                        type: 'object',
                        readonly: true,
                        fields: [
                            { id: 'role', title: '角色名', type: 'string', readonly: true },
                            {
                                id: 'permissions',
                                title: '权限项',
                                type: 'array',
                                readonly: true,
                                items: {
                                    type: 'object',
                                    fields: [
                                        { id: 'permission', title: '权限项ID', type: 'string', readonly: true },
                                        { id: 'pageId', title: '权限项关联的页面ID', type: 'string', readonly: true },
                                        { id: 'role', title: '权限项关联的角色名', type: 'string', readonly: true },
                                    ],
                                },
                            },
                        ],
                    },
                },
                { id: 'orgBgId', title: 'Bg ID', type: 'string', readonly: true, hidden: true },
                { id: 'orgDepartmentId', title: '部门ID', type: 'string', readonly: true, hidden: true },
                { id: 'orgCenterId', title: '中心ID', type: 'string', readonly: true, hidden: true },
                { id: 'orgGroupId', title: '小组ID', type: 'string', readonly: true, hidden: true },
            ],
            methods: [
                { id: 'logout', title: '退出登录', description: '注意：会离开当前页面' },
                SSO_DEPLOY && {
                    id: 'switchAccount',
                    title: '切换账号',
                    description: '显示账号切换浮窗。\n\n可以 await 并得到布尔值，表示用户是否执行了切换操作。',
                    arguments: [
                        { id: 'shallRedirect', type: 'boolean', description: '切换后是否刷新页面？默认 true' },
                    ],
                },
            ].filter(Boolean),
        },
        {
            id: 'emitter',
            title: '全局自定义事件',
            type: 'object',
            readonly: true,
            'uc:bindable': false,
            'uc:collapsed': true,
            fields: [
                {
                    id: 'EventName',
                    title: '平台预设事件',
                    type: 'object',
                    readonly: true,
                    'uc:bindable': false,
                    'uc:collapsed': true,
                    fields: [
                        { id: 'ROUTER_BEFORE_EACH', title: '全局前置守卫', type: 'string' },
                        { id: 'ROUTER_AFTER_EACH', title: '全局后置守卫', type: 'string' },
                        { id: 'PAGE_INIT', title: '页面初始化', type: 'string' },
                        { id: 'PAGE_CREATED', title: '页面创建', type: 'string' },
                        { id: 'PAGE_BEFORE_DESTROY', title: '页面销毁前', type: 'string' },
                        { id: 'PAGE_DESTROYED', title: '页面销毁', type: 'string' },
                        { id: 'PAGE_DATASOURCE_READY', title: '页面数据源就绪', type: 'string' },
                        { id: 'PAGE_TITLE_CHANGE', title: '页面标题修改', type: 'string' },
                    ],
                },
            ],
            methods: [
                {
                    id: 'on',
                    title: '监听事件',
                    description: '',
                    arguments: [
                        { id: 'eventName', type: 'string', description: '事件名', required: true },
                        { id: 'fn', type: 'any', description: '事件处理函数', required: true },
                    ],
                },
                {
                    id: 'once',
                    title: '监听事件(仅触发一次)',
                    description: '',
                    arguments: [
                        { id: 'eventName', type: 'string', description: '事件名', required: true },
                        { id: 'fn', type: 'any', description: '事件处理函数', required: true },
                    ],
                },
                {
                    id: 'off',
                    title: '取消监听事件',
                    description: '',
                    arguments: [
                        { id: 'eventName', type: 'string', description: '事件名', required: true },
                        { id: 'fn', type: 'any', description: '事件处理函数' },
                    ],
                },
                {
                    id: 'emit',
                    title: '触发事件',
                    description: '',
                    arguments: [
                        { id: 'eventName', type: 'string', description: '事件名', required: true },
                        { id: 'args', type: 'any', description: '事件参数' },
                    ],
                },
            ],
        },
        {
            id: 'permission',
            title: '权限',
            type: 'object',
            readonly: true,
            'uc:bindable': false,
            'uc:collapsed': true,
            methods: [
                {
                    id: 'fetchRoleList',
                    title: '获取角色列表',
                    description: '',
                    arguments: [],
                },
                {
                    id: 'fetchRoleUserList',
                    title: '获取角色-用户列表',
                    description: '',
                    arguments: [
                        { id: 'bizId', type: 'string', description: '选填，根据业务ID过滤', default: '' },
                        { id: 'roleId', type: 'string', description: '选填，根据角色ID过滤', default: '' },
                        { id: 'opt', type: 'object', description: '选填，用户id或类型type限定', default: { id: '', type: '' } },
                    ],
                },
                {
                    id: 'fetchRolePermissionList',
                    title: '获取角色-权限列表',
                    description: '',
                    arguments: [
                        { id: 'roleId', type: 'string', description: '角色ID' },
                    ],
                },
                {
                    id: 'fetchRoleView',
                    title: '获取角色相关权限及用户',
                    description: '',
                    arguments: [
                        { id: 'roleId', type: 'string', description: '角色ID' },
                    ],
                },
                {
                    id: 'fetchPermissionList',
                    title: '获取自定义权限列表',
                    description: '',
                    arguments: [],
                },
                {
                    id: 'fetchBuiltinPermissionList',
                    title: '获取内置权限列表',
                    description: '',
                    arguments: [],
                },
                {
                    id: 'fetchApiPermissionList',
                    title: '获取接口-权限列表',
                    description: '',
                    arguments: [],
                },
                {
                    id: 'createRole',
                    title: '新建角色',
                    description: '',
                    arguments: [
                        {
                            id: 'data',
                            title: '角色信息',
                            type: 'object',
                            fields: [
                                { id: 'name', type: 'string', description: '角色英文名' },
                                { id: 'cname', type: 'string', description: '角色中文名' },
                                { id: 'type', enum: [{ value: '' }, { value: 'biz' }], description: '类型，一般为空字符串即可，若是业务类型角色，则是 biz' },
                            ],
                        },
                    ],
                },
                {
                    id: 'updateRole',
                    title: '更新角色',
                    description: '',
                    arguments: [
                        {
                            id: 'data',
                            title: '角色信息',
                            description: '只有 cname 可以更新',
                            type: 'object',
                            fields: [
                                { id: 'name', type: 'string', description: '角色英文名' },
                                { id: 'cname', type: 'string', description: '角色中文名' },
                                { id: 'type', enum: [{ value: '' }, { value: 'biz' }], description: '类型，一般为空字符串即可，若是业务类型角色，则是 biz' },
                            ],
                        },
                    ],
                },
                {
                    id: 'deleteRole',
                    title: '删除角色',
                    description: '',
                    arguments: [
                        { id: 'name', type: 'string', description: '角色英文名' },
                    ],
                },
                {
                    id: 'createPermission',
                    title: '新建权限',
                    description: '',
                    arguments: [
                        {
                            id: 'data',
                            title: '权限信息',
                            type: 'object',
                            fields: [
                                { id: 'name', type: 'string', description: '权限英文名' },
                                { id: 'cname', type: 'string', description: '权限中文名' },
                                { id: 'type', enum: [{ value: 'page' }, { value: 'api' }, { value: 'set' }], description: '类型' },
                                { id: 'relatedPages', type: 'string', description: '类型为 page 时，可关联页面id, 逗号分隔' },
                            ],
                        },
                    ],
                },
                {
                    id: 'updatePermission',
                    title: '更新权限',
                    description: '',
                    arguments: [
                        {
                            id: 'data',
                            title: '权限信息',
                            description: '只有 cname 和 relatedPages 可以更新',
                            type: 'object',
                            fields: [
                                { id: 'name', type: 'string', description: '权限英文名' },
                                { id: 'cname', type: 'string', description: '权限中文名' },
                                { id: 'type', enum: [{ value: 'page' }, { value: 'api' }, { value: 'set' }], description: '类型' },
                                { id: 'relatedPages', type: 'string', description: '类型为 page 时，可关联页面id, 逗号分隔' },
                            ],
                        },
                    ],
                },
                {
                    id: 'deletePermission',
                    title: '删除权限',
                    description: '',
                    arguments: [
                        { id: 'name', type: 'string', description: '权限英文名' },
                    ],
                },
                {
                    id: 'createRoleUser',
                    title: '新增角色成员',
                    description: '',
                    arguments: [
                        {
                            id: 'data',
                            title: '角色成员信息',
                            type: 'object',
                            fields: [
                                { id: 'role', type: 'string', description: '角色英文名' },
                                { id: 'userId', type: 'string', description: '用户英文名' },
                                { id: 'userName', type: 'string', description: '名称' },
                                { id: 'userType', enum: [{ value: 'staff' }, { value: 'org' }], description: '用户类型' },
                                { id: 'bizId', type: 'string', description: '业务id, 业务隔离，若角色是业务类型，必填' },
                                { id: 'expireAt', type: 'number', description: '过期时间截，ms' },
                            ],
                        },
                    ],
                },
                {
                    id: 'deleteRoleUser',
                    title: '删除角色成员',
                    description: '',
                    arguments: [
                        { id: 'id', type: 'string', description: '角色成员记录id' },
                    ],
                },
                {
                    id: 'createRolePermission',
                    title: '新增角色权限',
                    description: '',
                    arguments: [
                        {
                            id: 'data',
                            title: '角色权限信息',
                            type: 'object',
                            fields: [
                                { id: 'role', type: 'string', description: '角色英文名' },
                                { id: 'permission', type: 'string', description: '权限英文名' },
                                { id: 'pageId', type: 'string', description: '页面id，页面相关权限必填' },
                            ],
                        },
                    ],
                },
                {
                    id: 'deleteRolePermission',
                    title: '删除角色权限',
                    description: '',
                    arguments: [
                        { id: 'id', type: 'string', description: '角色权限记录id' },
                    ],
                },
                {
                    id: 'batchDeleteRolePermission',
                    title: '批量删除角色权限',
                    description: '',
                    arguments: [
                        { id: 'ids', type: 'array', description: '角色权限记录id列表', items: { type: 'string' } },
                    ],
                },
                {
                    id: 'createApiPermission',
                    title: '新增接口权限绑定',
                    description: '',
                    arguments: [
                        {
                            id: 'data',
                            title: '接口权限绑定信息',
                            type: 'object',
                            fields: [
                                { id: 'name', type: 'string', description: '接口名' },
                                { id: 'permission', type: 'string', description: '权限英文名' },
                                { id: 'api', type: 'string', description: '' },
                                { id: 'method', type: 'string', description: '' },
                                { id: 'type', enum: [{ value: 'collection-cgi' }, { value: 'cloud-function' }], description: '' },
                            ],
                        },
                    ],
                },
                {
                    id: 'deleteApiPermission',
                    title: '删除接口权限绑定',
                    description: '',
                    arguments: [
                        { id: 'id', type: 'string', description: '接口权限绑定记录id' },
                    ],
                },
            ],
        },
        // 获取运行时公告
        {
            id: 'announcement',
            title: '公告',
            type: 'object',
            readonly: true,
            methods: [
                {
                    id: 'getList',
                    title: '获取公告列表',
                    description: '',
                    arguments: [
                        { id: 'filter', type: 'string', description: '过滤条件' },
                    ],
                },
                {
                    id: 'refresh',
                    title: '刷新公告',
                    description: '',
                },
            ],
        },
        {
            id: 'watermark',
            title: '水印',
            type: 'object',
            readonly: true,
            methods: [
                {
                    id: 'show',
                    title: '显示水印',
                },
            ],
        },
        {
            id: 'workflow',
            title: '审批流',
            type: 'object',
            readonly: true,
            'uc:bindable': false,
            'uc:collapsed': true,
            fields: [
                {
                    id: 'relatedEntityList',
                    title: '相关流程单',
                    type: 'array',
                    readonly: true,
                    items: {
                        type: 'object',
                        fields: [
                            { id: '_id', title: '流程单ID', type: 'string', readonly: true },
                            { id: 'handlers', title: '审批人', type: 'string', readonly: true },
                            { id: 'status', title: '流程状态节点ID', type: 'string', readonly: true },
                            { id: 'statusName', title: '流程状态节点名', type: 'string', readonly: true },
                            { id: 'flowStatus', title: '流程状态', type: 'string', readonly: true },
                            { id: 'env', title: '环境', type: 'string', readonly: true },
                            { id: 'objectId', title: '实体ID', type: 'string', readonly: true },
                            { id: 'flowId', title: '流程ID', type: 'string', readonly: true },
                        ],
                    },
                },
            ],
            methods: [
                {
                    id: 'fetchEntityList',
                    title: '获取流程单列表',
                    description: '',
                    arguments: [
                        { id: 'type', type: 'string', description: '流程单类型' },
                        { id: 'filter', type: 'string', description: '筛选条件' },
                    ],
                },
                {
                    id: 'fetchWorkflowEntity',
                    title: '获取流程单详情',
                    description: '',
                    arguments: [
                        { id: 'entityId', type: 'string', description: '流程单ID' },
                    ],
                },
                {
                    id: 'fetchEntityRecords',
                    title: '获取流程审批记录',
                    description: '',
                    arguments: [
                        { id: 'entityId', type: 'string', description: '流程单ID' },
                    ],
                },
                {
                    id: 'start',
                    title: '发起流程',
                    description: '',
                    arguments: [
                        { id: 'workflowId', type: 'string', description: '流程配置ID' },
                        { id: 'data', type: 'object', description: '审批数据' },
                    ],
                },
                {
                    id: 'transition',
                    title: '审批流程',
                    description: '',
                    arguments: [
                        { id: 'entityId', type: 'string', description: '流程单ID' },
                        { id: 'data', type: 'object', description: '审批数据' },
                    ],
                },
                {
                    id: 'restart',
                    title: '重新发起流程',
                    description: '',
                    arguments: [
                        { id: 'entityId', type: 'string', description: '流程单ID' },
                        { id: 'payload', type: 'object', description: '流程数据' },
                    ],
                },
                {
                    id: 'abort',
                    title: '撤销流程',
                    description: '',
                    arguments: [
                        { id: 'entityId', type: 'string', description: '流程单ID' },
                    ],
                },
                {
                    id: 'isEntityProcessing',
                    title: '流程单是否进行中',
                    description: '',
                    arguments: [
                        { id: 'entity', type: 'object', description: '流程单' },
                    ],
                },
                {
                    id: 'canApproveEntity',
                    title: '当前用户能否审批',
                    description: '',
                    arguments: [
                        { id: 'entity', type: 'object', description: '流程单' },
                    ],
                },
                {
                    id: 'assignEntityHandlers',
                    title: '重新指派审批人',
                    description: '',
                    arguments: [
                        { id: 'entityId', type: 'string', description: '流程单ID' },
                        { id: 'handlers', type: 'string', description: '审批人, 多个审批人使用英文逗号(,)分割' },
                    ],
                },
            ],
        },
        {
            id: 'projectInfo',
            title: '应用信息',
            type: 'object',
            readonly: true,
            'uc:bindable': false,
            'uc:collapsed': true,
            fields: [
                { id: 'id', title: '应用ID', type: 'string', readonly: true },
                { id: 'name', title: '应用名称', type: 'string', readonly: true },
                { id: 'logo', title: '应用图标', type: 'string', readonly: true, ui: { type: getAvailableComponent('public', COMP.image) } },
            ],
        },
        {
            id: 'pageInfo',
            title: '当前页面信息',
            type: 'object',
            readonly: true,
            'uc:bindable': false,
            'uc:collapsed': true,
            fields: getPageInfoFields(),
        },
        {
            id: 'mainPageInfo',
            title: '主页面信息',
            type: 'object',
            readonly: true,
            'uc:bindable': false,
            'uc:collapsed': true,
            fields: getPageInfoFields(),
        },
        {
            id: 'pageList',
            title: '页面列表',
            type: 'array',
            readonly: true,
            'uc:bindable': false,
            'uc:collapsed': true,
            items: {
                type: 'object',
                fields: getPageInfoFields(),
            },
        },
        {
            id: 'sidebar',
            title: '菜单',
            type: 'object',
            readonly: true,
            'uc:bindable': false,
            'uc:collapsed': true,
            methods: [
                {
                    id: 'getCurrentGroup',
                    title: '获取当前的菜单组',
                    arguments: [],
                },
                {
                    id: 'setCurrentGroup',
                    title: '设置当前的菜单组',
                    arguments: [
                        { id: 'name', type: 'string', description: '菜单组名' },
                    ],
                },
                {
                    id: 'setSelectedMenu',
                    title: '设置当前选中的菜单项',
                    arguments: [
                        { id: 'names', type: 'array', items: { type: 'string' }, description: '多级的菜单项名' },
                    ],
                },
                {
                    id: 'setCollapsed',
                    title: '设置当前菜单收起/展开态',
                    arguments: [
                        { id: 'collapsed', type: 'boolean', description: '是否收起菜单' },
                    ],
                },
                {
                    id: 'getSidebar',
                    title: '获取当前菜单数据',
                    arguments: [],
                },
                {
                    id: 'getSystemSidebar',
                    title: '获取平台菜单数据',
                    arguments: [],
                },
                {
                    id: 'setSidebar',
                    title: '设置菜单数据',
                    arguments: [
                        { id: 'sidebar', type: 'object', description: '菜单数据' },
                    ],
                },
                {
                    id: 'getCurrentSidebarNames',
                    title: '获取当前菜单名',
                    arguments: [],
                    returnValue: { type: 'array', items: { type: 'string' } },
                },
            ],
        },
        { id: 'host', title: '当前域名', type: 'string', readonly: true },
        { id: 'path', title: '当前路径', type: 'string', readonly: true },
        { id: 'query', title: '路由查询参数', type: 'object', readonly: true },
        { id: 'params', title: '路由动态参数', type: 'object', readonly: true },
        { id: 'isMiniProgram', title: '是否小程序', type: 'boolean', readonly: true },
        { id: 'isNarrowScreen', title: '是否窄屏', type: 'boolean', readonly: true },
        { id: 'screen', title: '屏幕尺寸信息', type: 'object', readonly: true, 'uc:bindable': false },
        {
            id: 'message',
            title: '提示',
            type: 'object',
            readonly: true,
            'uc:bindable': false,
            'uc:collapsed': true,
            methods: [
                {
                    id: 'success',
                    title: '成功提示',
                    arguments: [{ id: 'content', type: 'string', description: '提示内容' }],
                },
                {
                    id: 'warning',
                    title: '警告提示',
                    arguments: [{ id: 'content', type: 'string', description: '提示内容' }],
                },
                {
                    id: 'error',
                    title: '错误提示',
                    arguments: [{ id: 'content', type: 'string', description: '提示内容' }],
                },
                {
                    id: 'info',
                    title: '提示',
                    arguments: [{ id: 'content', type: 'string', description: '提示内容' }],
                },
                {
                    id: 'loading',
                    title: '加载提示',
                    arguments: [{ id: 'content', type: 'string', description: '提示内容' }],
                },
            ],
        },
        {
            id: 'atoms',
            title: '行为事件',
            type: 'object',
            readonly: true,
            'uc:bindable': false,
            'uc:collapsed': true,
            methods: [
                {
                    id: 'alert',
                    title: '弹出消息窗口',
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            required: true,
                            fields: [
                                { id: 'text', type: 'string', required: true, description: '内容' },
                                { id: 'icon', type: 'string', enum: [{ value: '' }, { value: 'info' }, { value: 'success' }, { value: 'error' }, { value: 'success' }] },
                            ],
                        },
                    ],
                },
                {
                    id: 'confirm',
                    title: '弹出确认框',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            required: true,
                            fields: [
                                { id: 'text', type: 'string', required: true, description: '内容' },
                                { id: 'okText', type: 'string', description: '确认按钮文本' },
                                { id: 'cancelText', type: 'string', description: '取消按钮文本' },
                                { id: 'icon', type: 'string', description: '图标' },
                            ],
                        },
                    ],
                    returnValue: { type: 'boolean' },
                },
                {
                    id: 'prompt',
                    title: '弹出文字输入框',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            required: true,
                            fields: [
                                { id: 'message', type: 'string', required: true, description: '内容' },
                                { id: 'defaultValue', type: 'string', description: '默认输入文本' },
                                {
                                    id: 'inputType',
                                    type: 'string',
                                    description: '文本类型',
                                    enum: [{ value: 'input' }, { value: 'textarea' }, { value: 'password' }],
                                },
                                { id: 'allowEmpty', type: 'boolean', description: '允许空字符串' },
                            ],
                        },
                    ],
                },
                {
                    id: 'message',
                    title: '弹出提示',
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            required: true,
                            fields: [
                                { id: 'text', type: 'string', required: true, description: '内容' },
                                { id: 'type', type: 'string', enum: [{ value: 'success' }, { value: 'error' }, { value: 'info' }, { value: 'loading' }, { value: 'warn' }] },
                                { id: 'duration', type: 'number', description: '持续时间' },
                            ],
                        },
                    ],
                },
                {
                    id: 'notification',
                    title: '弹出通知框',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            required: true,
                            fields: [
                                { id: 'message', type: 'string', required: true, description: '内容' },
                                { id: 'description', type: 'string', required: true, description: '描述' },
                                { id: 'type', type: 'string', description: '通知类型', enum: [{ value: 'open' }, { value: 'error' }, { value: 'success' }, { value: 'warn' }] },
                                { id: 'placement', type: 'string', description: '位置', enum: [{ value: 'topLeft' }, { value: 'topRight' }, { value: 'bottomLeft' }, { value: 'bottomRight' }] },
                                { id: 'duration', type: 'number', description: '持续时间' },
                            ],
                        },
                    ],
                },
                {
                    id: 'validateForm',
                    title: '校验表单',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            required: true,
                            fields: [
                                { id: 'target', type: 'string', required: true, description: '表单对象' },
                                { id: 'errorMessage', type: 'string', description: '校验错误信息' },
                            ],
                        },
                    ],
                    returnValue: { type: 'boolean' },
                },
                {
                    id: 'invokeLessCode',
                    title: '调用LessCode方法',
                    isAsync: true,
                    arguments: [
                        { id: 'methodName', type: 'string', required: true, description: 'LessCode方法名' },
                    ],
                },
                {
                    id: 'clipboardWriteText',
                    title: '复制文本',
                    isAsync: true,
                    arguments: [
                        { id: 'text', type: 'string', required: true, description: '文本' },
                        {
                            id: 'opt',
                            type: 'object',
                            fields: [
                                { id: 'successMessage', type: 'boolean', description: '是否弹出复制成功提醒' },
                            ],
                        },
                    ],
                },
                {
                    id: 'invokeDataSourceMethod',
                    title: '数据源操作',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            required: true,
                            fields: [
                                { id: 'id', type: 'string', required: true, description: '数据源ID' },
                                { id: 'method', type: 'string', required: true, description: '数据源方法名' },
                                { id: 'args', type: 'any', description: '数据源方法入参' },
                            ],
                        },
                    ],
                },
                {
                    id: 'dataSourceReInit',
                    title: '数据源重新初始化',
                    isAsync: true,
                },
                {
                    id: 'uploadToCOS',
                    title: '上传至COS',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            required: true,
                            fields: [
                                { id: 'id', type: 'string', required: true, description: 'COS桶ID' },
                                { id: 'file', type: 'object', required: true, description: '文件字符串' },
                                { id: 'path', type: 'string', description: '上传路径' },
                                { id: 'envConfig', type: 'object', description: '多环境配置' },
                                { id: 'headers', type: 'object', description: '上传头部' },
                            ],
                        },
                    ],
                },
                {
                    id: 'exportExcel',
                    title: 'Excel导出',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            required: true,
                            fields: [
                                { id: 'data', type: 'array', required: true, description: '导出数据' },
                                {
                                    id: 'fields',
                                    type: 'array',
                                    required: true,
                                    description: '导出字段',
                                    items: {
                                        type: 'object',
                                        fields: [
                                            { id: 'dataKey', type: 'string', required: true, description: '字段key' },
                                            { id: 'colName', type: 'string', required: true, description: '字段名' },
                                        ],
                                    },
                                },
                                { id: 'fileName', type: 'string', required: true, description: '文件名' },
                                { id: 'sheetName', type: 'string', required: true, description: 'Sheet名' },
                                { id: 'format', type: 'string', description: '导出格式', enum: [{ value: 'xlsx' }, { value: 'xlsm' }] },
                            ],
                        },
                    ],
                },
                {
                    id: 'importExcel',
                    title: 'Excel导入',
                    isAsync: true,
                    returnValue: { type: 'object' },
                },
                {
                    id: 'getFileData',
                    title: '提取文件数据',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            fields: [
                                { id: 'multiple', type: 'boolean', description: '是否多选' },
                                { id: 'enableDirectory', type: 'boolean', description: '是否支持文件夹' },
                                { id: 'parseTimeout', type: 'number', description: '解析超时时间' },
                            ],
                        },
                    ],
                },
                {
                    id: 'openLink',
                    title: '跳转链接',
                    isAsync: true,
                    arguments: [
                        { id: 'link', type: 'string', required: true, description: '链接地址' },
                        { id: 'newWindow', type: 'boolean', description: '是否新窗口打开' },
                    ],
                },
                {
                    id: 'historyBack',
                    title: '返回上一页',
                },
                {
                    id: 'refreshPage',
                    title: '刷新页面',
                },
                {
                    id: 'reloadRenderer',
                    title: '刷新页面片',
                },
                {
                    id: 'pageScroll',
                    title: '滚动页面',
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            fields: [
                                { id: 'position', type: 'string', required: true, description: '滚动位置', enum: [{ value: 'top' }, { value: 'bottom' }, { value: 'y' }, { value: 'component' }] },
                                { id: 'y', type: 'number', description: '垂直滚动距离(px)' },
                                { id: 'componentId', type: 'string', description: '组件ID' },
                                { id: 'componentOffsetType', type: 'string', description: '组件偏移类型', enum: [{ value: 'start' }, { value: 'end' }] },
                                { id: 'componentOffset', type: 'number', description: '组件偏移距离(px)' },
                                { id: 'behavior', type: 'string', description: '滚动行为', enum: [{ value: 'instant' }, { value: 'smooth' }] },
                            ],
                        },
                    ],
                },
                {
                    id: 'saveDraft',
                    title: '保存草稿',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            fields: [
                                { id: 'namespace', type: 'string', required: true, description: '草稿命名空间' },
                                { id: 'showConfirm', type: 'boolean', description: '是否显示确认弹窗' },
                            ],
                        },
                    ],
                },
                {
                    id: 'restoreDraft',
                    title: '恢复草稿',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            fields: [
                                { id: 'namespace', type: 'string', required: true, description: '草稿命名空间' },
                                { id: 'showConfirm', type: 'boolean', description: '是否显示确认弹窗' },
                                { id: 'remainDraftAfterCancel', type: 'boolean', description: '取消后是否保留草稿' },
                            ],
                        },
                    ],
                },
                {
                    id: 'removeDraft',
                    title: '删除草稿',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            fields: [
                                { id: 'namespace', type: 'string', required: true, description: '草稿命名空间' },
                            ],
                        },
                    ],
                },
                {
                    id: 'email',
                    title: '邮件通知',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            fields: [
                                { id: 'to', type: 'string', required: true, description: '收件人邮箱' },
                                { id: 'cc', type: 'string', description: '抄送人邮箱' },
                                { id: 'bcc', type: 'string', description: '密送人邮箱' },
                                { id: 'title', type: 'string', required: true, description: '邮件标题' },
                                { id: 'content', type: 'string', required: true, description: '邮件内容' },
                            ],
                        },
                    ],
                },
                {
                    id: 'wxworkRobot',
                    title: '企微机器人通知',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            fields: [
                                { id: 'robotId', type: 'string', required: true, description: '机器人ID' },
                                { id: 'content', type: 'string', required: true, description: '通知内容' },
                                { id: 'successMessage', type: 'boolean', required: true, description: '是否弹出成功提醒' },
                            ],
                        },
                    ],
                },
                {
                    id: 'wxworkNotification',
                    title: '企业微信通知',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            fields: [
                                { id: 'app', type: 'string', required: true, description: '应用ID' },
                                { id: 'receivers', type: 'string', required: true, description: '接收人，多个接收人使用英文逗号(,)分割' },
                                { id: 'type', type: 'string', required: true, description: '通知类型', enum: [{ value: 'text' }, { value: 'textcard' }, { value: 'markdown' }] },
                                { id: 'content', type: 'string', description: '通知内容' },
                                { id: 'title', type: 'string', description: '通知标题' },
                                { id: 'description', type: 'string', description: '通知描述' },
                                { id: 'url', type: 'string', description: '通知链接' },
                                { id: 'buttonText', type: 'string', description: '通知按钮文本' },
                            ],
                        },
                    ],
                },
                {
                    id: 'openWxwork',
                    title: '唤起企业微信',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            fields: [
                                { id: 'type', type: 'string', required: true, enum: [{ value: 'username' }, { value: 'uin' }] },
                                { id: 'value', type: 'string', required: true },
                            ],
                        },
                    ],
                },
                {
                    id: 'sendGptMessage',
                    title: '发送GPT消息',
                    isAsync: true,
                    arguments: [
                        {
                            id: 'params',
                            type: 'object',
                            fields: [
                                { id: 'systemContent', type: 'string', required: true, description: '系统预设Prompt' },
                                { id: 'message', type: 'string', required: true, description: '消息内容' },
                                { id: 'type', type: 'string', enum: [{ value: 'sync' }, { value: 'flow' }], description: '返回消息形式' },
                                {
                                    id: 'flowSetting',
                                    type: 'object',
                                    fields: [
                                        { id: 'flowType', type: 'string', required: true, enum: [{ value: 'joinString' }, { value: 'callMethod' }] },
                                        { id: 'jsonString', type: 'string', description: 'JSON字符串' },
                                        { id: 'callMethod', type: 'string', description: '调用方法' },
                                    ],
                                },
                                { id: 'system', type: 'string', enum: [{ value: 'hy' }, { value: 'azure' }], description: '语言模型' },
                            ],
                        },
                    ],
                },
            ],
        },
        getSchemaOfUtilsForUser(),
        getFlowSchema(),
    ],
}));
