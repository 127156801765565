import fetch from '@utils/fetch';
import wujiFetch from '@utils/wujiFetch';
import { getDataOrThrow } from '@utils/common';
import loadModule from '@loaders/page/loadModule';
import openLocalLoadErrorModal from '@/components/localLoadErrorModal';
import getXyManagePathPrefix from '@/utils/getXyManagePathPrefix';
import { getLocalBaseApiUrl, getLocalBaseUrl } from '@/config/cli-settings';
import pageReport from '@utils/reporter/page';
import { addJsonPrefixIfOffline } from '@utils/offline';
import { message } from '@utils/wuji-message';

import { getGeneratedPage } from '@/loaders/pageGenerator';

const codeType = 'code';

class PageLoader {
  constructor() {
    this.debug = false;
    this.port = 9998;
  }

  isDebug() {
    return !!this.debug;
  }

  initHmr(port) {
    this.debug = true;
    this.port = port;
  }

  async list(projectId, { url, query, debugQuery } = {}) {
    const params = new URLSearchParams();
    Object.entries({ ...query, projectid: projectId }).forEach(([key, value]) => {
      params.set(key, value);
    });

    const saveList = await wujiFetch(
      url,
      {
        method: 'GET',
        timeout: 5000,
      },
    );

    if (!this.debug) return saveList;

    const debugList = await this.loadDebugList(projectId, debugQuery);
    // 存在项做合并
    const result = saveList;
    debugList.forEach((info) => {
      const index = result.findIndex(({ path }) => path === info.path);

      // 找不到相同路径的，加入池子
      if (index === -1) {
        result.push(info);
        return;
      }

      // 找到，但是非本地开发页面，返回
      if (result[index].type !== codeType) return;

      // 强覆盖
      result[index] = {
        ...result[index],
        ...info,
      };
    });
    return result;
  }

  // 获取页面数据(Basic)
  async loadBasicList(projectId, { query = {}, debugQuery = {} } = {}, env) {
    const params = new URLSearchParams();
    Object.entries({ ...query, projectid: projectId }).forEach(([key, value]) => {
      params.set(key, value);
    });

    const url = `${getXyManagePathPrefix({ envId: env })}/basic/page?${params.toString()}`;

    return this.list(projectId, { url, query, debugQuery });
  }

  // 获取页面数据(完整)
  async loadList(projectId, { query = {}, debugQuery = {} } = {}, env) {
    const params = new URLSearchParams();
    Object.entries({ ...query, projectid: projectId }).forEach(([key, value]) => {
      params.set(key, value);
    });

    const url = `${getXyManagePathPrefix({ envId: env })}/page${addJsonPrefixIfOffline()}?${params.toString()}`;

    return this.list(projectId, { url, query, debugQuery });
  }

  // debug环境
  async loadDebugList(projectId, debugQuery = {}) {
    const filterMatch = (filterKey, value) => (!debugQuery[filterKey]
      || (value.includes(debugQuery[filterKey])));

    try {
      const list = (await fetch(`${getLocalBaseApiUrl(this.port)}/page?projectid=${projectId}`, {
        method: 'GET',
        credentials: 'omit',
      }, 5000, false)
        .then(getDataOrThrow))
        .filter(({ path, name }) => filterMatch('path', path) && filterMatch('name', name))
        .map((pageInfo) => {
          const {
            pageId,
            path,
            id,
            name,
            layoutType,
            pageType,
            pageAttr,
          } = pageInfo;
          return {
            projectId,
            pageId: pageId || id,
            name,
            path,
            type: 'code',
            layoutType: layoutType || 'header-sidebar-content',
            pageType: pageType || 'content',
            pageAttr,
            sourceCodeConfig: {
              id,
              // page HMR端口另开，目前规则是在base端口上+1
              src: `${getLocalBaseUrl(this.port + 1)}/pages/${id}/index.js?t=${+new Date}`,
            },
            _state: { debug: true },
          };
        });
      return list;
    } catch (err) {
      console.error(err);
      openLocalLoadErrorModal({ debugType: '页面' });
    }
  }

  async getPageDetail({ projectId, pageId }) {
    if (this.debug) {
      const debugList = await this.loadDebugList(projectId);
      const debugPageInfo = debugList.find(pageInfo => pageInfo.pageId === pageId);

      if (debugPageInfo) return debugPageInfo;
    }
    const fromGenerated = await (await getGeneratedPage({ projectId, pageId }))?.getPageConfig?.();
    if (fromGenerated) {
      return fromGenerated;
    }
    return await wujiFetch(`${getXyManagePathPrefix()}/page/${pageId}${addJsonPrefixIfOffline()}?projectid=${projectId}`);
  }

  async load(id, src) {
    let pageModule;
    try {
      pageModule = await loadModule({ id, src }, this.debug);
      pageReport({
        success: true,
        id,
        src,
        errorStack: '',
      });
    } catch (err) {
      const errorMessage = (err instanceof Error) ? err.message : String(err);

      pageReport({
        success: false,
        id,
        src,
        errorStack: err.stack,
        errorMessage,
      });
      message.error('页面JS资源加载失败');
      console.error('页面JS资源加载失败', err);
    }

    return pageModule;
  }
}

export default new PageLoader();
