import { onBeforeMount, onUnmounted, shallowReactive, watch } from '@vue/composition-api';
export function useSWR(fetcher, options = {}) {
    let lastPromise;
    const reload = async () => {
        const promise = fetcher();
        lastPromise = promise;
        response.loading = true;
        if (options.clearValueBeforeLoad) {
            response.value = undefined;
            response.error = null;
        }
        return promise.then((result) => {
            if (promise !== lastPromise)
                return lastPromise; // another req happens
            response.loading = false;
            if (options.updateHook) {
                options.updateHook(response, result);
            }
            else {
                response.value = result;
            }
            response.error = null;
            return result;
        }, (error) => {
            if (promise !== lastPromise)
                return lastPromise; // another req happens
            response.loading = false;
            if (options.clearValueIfError)
                response.value = undefined;
            response.error = error;
            throw error;
        });
    };
    const cancel = () => {
        lastPromise = undefined;
    };
    const response = shallowReactive({
        loading: true,
        value: undefined,
        error: null,
        reload,
        cancel,
        waitForReady: () => lastPromise || reload(),
    });
    if (options.immediate)
        onBeforeMount(reload);
    if (options.watch)
        watch(options.watch, reload);
    onUnmounted(cancel);
    return response;
}
