/* eslint-disable no-param-reassign */
// 宽松水印模板
export const getLooseConfig = () => ({
    paddingX: 100,
    paddingY: 100,
    content: '{{ user.name }}',
    fontSize: 20,
    fillStyle: 'rgba(217, 217, 217, 0.5)',
    rotate: -30,
});
// 紧密水印模板
export const getCloseConfig = () => ({
    paddingX: 50,
    paddingY: 50,
    content: '{{ user.name }}',
    fontSize: 20,
    fillStyle: 'rgba(217, 217, 217, 0.5)',
    rotate: -30,
});
const defaultWatermarkId = 'wuji-watermark';
// 计算水印内容大小
const getWatermarkContentSize = ({ content, fontSize, fontFamily, rotate }) => {
    const canvas = document.createElement('canvas');
    canvas.style.position = 'absolute';
    const ctx = canvas.getContext('2d');
    document.body.appendChild(canvas);
    if (!ctx)
        return { contentWidth: 0, contentHeight: 0 };
    ctx.font = `${fontSize}px ${fontFamily}`;
    document.body.removeChild(canvas);
    const text = ctx.measureText(content);
    ctx.fillText(content, 50, 50);
    const textWidth = text.width;
    const textHeight = fontSize;
    const contentWidth = textWidth * Math.abs(Math.cos((rotate * Math.PI) / 180))
        + textHeight * Math.abs(Math.sin((rotate * Math.PI) / 180));
    const contentHeight = textHeight * Math.abs(Math.cos((rotate * Math.PI) / 180))
        + textWidth * Math.abs(Math.sin((rotate * Math.PI) / 180));
    return {
        contentWidth,
        contentHeight,
    };
};
// 移除指定水印
export const removeWatermark = (id = defaultWatermarkId) => {
    const el = document.getElementById(id);
    if (el)
        el.remove();
};
export const renderWatermark = ({ id = defaultWatermarkId, position = 'absolute', container = document.body, textBaseline = 'middle', paddingX = 0, paddingY = 0, top = 0, bottom = 0, left = 0, right = 0, fontSize = 28, fillStyle = 'rgba(230, 230, 230, .25)', content = '请勿外传', rotate = 30, bgWidth = 0, bgHeight = 0, zIndex = 800, ignoreSize = false, // 不做 width 和 height 限制
disableEvent = true, // 禁止事件
 } = {}) => {
    const canvas = document.createElement('canvas');
    const fontFamily = '-apple-system, BlinkMacSystemFont, "PingFang SC", "Microsoft YaHei"';
    const font = `${fontSize}px ${fontFamily}`;
    const { contentWidth, contentHeight } = getWatermarkContentSize({
        content,
        fontSize,
        fontFamily,
        paddingX,
        paddingY,
        rotate,
    });
    const width = contentWidth + paddingX * 2;
    const height = contentHeight + paddingY * 2;
    canvas.setAttribute('width', `${width}`);
    canvas.setAttribute('height', `${height}`);
    const ctx = canvas.getContext('2d');
    const rectCenterPoint = { x: width / 2, y: height / 2 };
    if (!ctx)
        return;
    ctx.textBaseline = textBaseline;
    ctx.font = font;
    ctx.fillStyle = fillStyle;
    ctx.translate(rectCenterPoint.x, rectCenterPoint.y);
    ctx.rotate((rotate * Math.PI) / 180);
    ctx.translate(-rectCenterPoint.x, -rectCenterPoint.y);
    ctx.fillText(content, paddingX, paddingY + contentHeight / 2);
    const base64Url = canvas.toDataURL();
    const el = document.getElementById(id);
    const watermarkDiv = el || document.createElement('div');
    const watermarkWidth = container?.clientWidth ?? bgWidth;
    const watermarkHeight = container?.clientHeight ?? bgHeight;
    const size = `width: ${!!watermarkWidth ? `${watermarkWidth}px` : '100%'};
  height: ${!!watermarkHeight ? `${watermarkHeight}px` : '100%'};  
  `;
    const styleStr = `
    position: ${position};
    ${ignoreSize ? '' : size}
    z-index: ${zIndex};
    top: ${top}px;
    bottom: ${bottom}px;
    left: ${left}px;
    right: ${right}px;
    ${disableEvent ? 'pointer-events: none;' : ''}
    background-repeat: repeat;
    background-image: url('${base64Url}')`;
    watermarkDiv.setAttribute('id', id);
    watermarkDiv.setAttribute('style', styleStr);
    if (!el && container) {
        container.style.position = 'relative';
        container.insertBefore(watermarkDiv, container.firstChild);
    }
};
