import { Modal } from 'ant-design-vue';
import { BASE_API_PATH } from '@/config/constant';
import { getAppParams } from './path';
import { isRuntime as isRuntimeFn } from '@/utils/globalInfo';
import { useStore } from '@store/index';
import { getAppQuery } from '@utils/query';
let validateConfirming = false;
const validateXyManagePath = ({ enableBranch } = { enableBranch: false }) => {
    const store = useStore();
    const storeEnabledBranch = !!store.state.project.settings?.versionSetting?.enabledBranch;
    // 如果 branch 参数与 store 中 ProjectSetting 的配置不一致, 则提示用户, 拦截请求
    if (enableBranch !== storeEnabledBranch) {
        const content = '当前访问路径参数与项目配置不一致, 请刷新后重新操作或联系管理员';
        if (!validateConfirming) {
            validateConfirming = true;
            Modal.confirm({
                title: '请求错误',
                content,
                okText: '刷新页面',
                cancelButtonProps: { style: 'display: none;' },
                onOk: () => {
                    window.location.reload();
                },
            });
        }
        throw new Error(content);
    }
};
export default function getXyManagePathPrefix({ branchId, envId, isRuntimeRoute } = {}) {
    const isRuntime = isRuntimeFn();
    // branch和env在运行时都是挂在path上，编辑时都是挂在query上
    const localEnv = isRuntime ? getAppParams('env') : getAppQuery('env');
    const localBranch = isRuntime ? getAppParams('branch') : getAppQuery('branch');
    const env = envId || localEnv || 'dev';
    const branch = branchId || localBranch;
    const isDev = env === 'dev';
    const enableBranch = !!(isDev && branch);
    // 仅编辑时开发环境的接口校验和提示
    if (!isRuntime && isDev)
        validateXyManagePath({ enableBranch });
    if (enableBranch) {
        return isRuntime || isRuntimeRoute
            ? `${BASE_API_PATH}/xy/runtime/branch/${branch}/manage`
            : `${BASE_API_PATH}/xy/design/branch/${branch}/manage`;
    }
    return `${BASE_API_PATH}/xy/${isRuntime || isRuntimeRoute ? 'runtime' : 'design'}/env/${env}/manage`;
}
