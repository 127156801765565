/* eslint-disable no-param-reassign */
import { message } from 'ant-design-vue';
import { isPlainObject, flatten } from 'lodash';
import { newFunction } from '@tencent/ui-core/lib/utils/script';
import { getDefaultMixin } from '@utils/runtime-mixin';
import { CommonContext } from '@utils/global/api';
// 已加载模块的 require 方法
let $require = null;
export function getCurrentRequire() {
    return $require;
}
// 获取 LessCode 的代理上下文
export function getLessCodeProxyContext({ w, $app }) {
    const getProxy = (key) => new Proxy({}, {
        get(target, name) {
            return Reflect.get(w?.renderer?.[key], name);
        },
        set(target, name, value) {
            return Reflect.set(w?.renderer?.[key], name, value);
        },
        has(target, name) {
            return Reflect.has(w?.renderer?.[key], name);
        },
    });
    return {
        w,
        $app,
        state: getProxy('state'),
        data: getProxy('data'),
        props: getProxy('props'),
        $query: getProxy('$query'),
        $utils: getProxy('$utils'),
        $tips: getProxy('$tips'),
    };
}
export function generateCodeDeflate(argMap = {}) {
    argMap.require = $require;
    const keys = Object.keys(argMap);
    const values = Object.values(argMap);
    return function (pagelet) {
        if (!pagelet?.lessCode)
            return null;
        try {
            const funcBody = [
                'var __deflated_exports__;(function(){',
                pagelet.lessCode.replace(/\bexport default\b/, ';__deflated_exports__='),
                '})();return __deflated_exports__;',
            ].join('\n');
            const fn = newFunction(keys, funcBody);
            return fn(...values);
        }
        catch (err) {
            console.error('[LessCode] Cannot compile ', pagelet);
            console.error(err);
            return null;
        }
    };
}
function runCodeSnippet(code, argMap = {}) {
    return generateCodeDeflate(argMap)({ lessCode: code });
}
/* 获取运行时的 LessCode Mixin */
export function getProjectRuntimeLessCode(project, argMap = {}) {
    const code = project?.advanceConfig?.lessCode;
    if (code) {
        return runCodeSnippet(code, argMap);
    }
    return null;
}
/* 获取编辑时的 LessCode Mixin */
export function getProjectEditorLessCode(project, option) {
    const code = project?.advanceConfig?.lessCode;
    const projectId = project?.id;
    let instance = {};
    if (projectId) {
        const mode = 'editor';
        const w = new CommonContext({ mode });
        window.w = w;
        const defaultMixin = getDefaultMixin({ project, mode, w });
        if (code) {
            const codeInstance = runCodeSnippet(code);
            if (isPlainObject(codeInstance)) {
                instance = codeInstance;
            }
            else {
                message.error('应用 LessCode 执行失败, 请校验应用级 LessCode 代码');
            }
            // 注入默认的 Mixin
            if (Array.isArray(instance.mixins)) {
                instance.mixins = [...instance.mixins, defaultMixin];
            }
            else {
                instance.mixins = [defaultMixin];
            }
            //  过滤掉 Vue 生命周期函数
            if (option?.disableLifeCycleCallbacks) {
                [
                    'beforeCreate',
                    'created',
                    'beforeMount',
                    'mounted',
                    'beforeUpdate',
                    'updated',
                    'activated',
                    'deactivated',
                    'beforeDestroy',
                    'destroyed',
                    'errorCaptured',
                ].forEach((key) => {
                    delete instance[key];
                });
            }
        }
        else {
            instance.mixins = [defaultMixin];
        }
    }
    return instance;
}
async function defineLessCodeModule(filename, code, argMap = {}) {
    try {
        // find all `require("global/xxx")` in code
        const deps = new Set();
        const requireReg = /\brequire\(["'](global\/[^'"]+)["']\)/g;
        let match;
        while ((match = requireReg.exec(code)) !== null)
            deps.add(match[1]);
        const uuid = `lc${Date.now()}${Math.random()}`;
        const functionCode = [
            `window.__lessCodeInit["${uuid}"](function({ ${Object.keys(argMap).join(', ')} }) {`,
            `define('${filename}', ${JSON.stringify(['require', 'exports', 'module', ...deps])}, function(require, exports, module) {`,
            `// ${filename}`,
            `${code}`,
            '});',
            '});',
        ].join('\n');
        if (!window.__lessCodeInit)
            window.__lessCodeInit = {};
        await new Promise((rs, rj) => {
            window.__lessCodeInit[uuid] = (factory) => {
                window.__lessCodeInit[uuid] = null;
                try {
                    rs(factory(argMap));
                }
                catch (e) {
                    rj(e);
                }
            };
            const url = URL.createObjectURL(new Blob([functionCode], { type: 'text/javascript' }));
            const script = document.createElement('script');
            script.src = url;
            document.body.appendChild(script);
        });
    }
    catch (err) {
        console.error(err);
    }
}
// 获取模块名称
const getModuleNames = (m) => [m.path, m.path.replace(/\.[^/.]+$/, '')];
let vendorModuleLoadPromise;
const loadVendorModules = () => {
    // 准备类似 vue, dayjs, lodash 之类的
    vendorModuleLoadPromise ||= new Promise(rs => window.require([...Object.keys(window._xy_node_modules)], rs));
    return vendorModuleLoadPromise;
};
// 定义所有全局模块
export async function defineProjectModules(project, argMap = {}) {
    await loadVendorModules();
    console.time('DefineProjectModules');
    const modules = Object.values(project?.lessCodeModules ?? {});
    for (const m of modules) {
        const names = getModuleNames(m);
        for (let i = 0; i < names.length; i++) {
            const name = names[i];
            // 如果模块已经定义, 需要先移除定义再重新定义
            // eslint-disable-next-line no-prototype-builtins
            if (window.requirejs.s.contexts._.defined.hasOwnProperty(name)) {
                window.requirejs.undef(name);
            }
            let { code } = m;
            if (i !== 0) {
                // 避免重复初始化模块
                code = `module.exports = require('${names[0]}');`;
            }
            await defineLessCodeModule(name, code, argMap);
        }
    }
    await requireProjectModules(project);
    console.timeEnd('DefineProjectModules');
}
// 获取应用所有的模块名
const getAllProjectModulesNames = (project) => {
    const names = Object.values(project?.lessCodeModules ?? {}).map(m => getModuleNames(m));
    return flatten(names);
};
// 移除所有全局模块的定义
export function undefineProjectModules(project) {
    const modules = getAllProjectModulesNames(project);
    ;
    for (const m of modules) {
        window.requirejs.undef(m);
    }
}
// 加载所有全局模块
export async function requireProjectModules(project) {
    const modules = getAllProjectModulesNames(project);
    ;
    $require = await new Promise(rs => window.require(['require', ...modules], rs));
}
