import Apply from './Apply';

export default [
  {
    path: 'rbac',
    component: () => import(/* webpackChunkName: "perm" */ './RBAC'),
    name: 'rbac',
    meta: { layoutType: 'header-content' },
    children: [
      {
        path: 'user',
        component: () => import(/* webpackChunkName: "perm" */ './UserList'),
        name: 'rbac.user-list',
      },
      {
        path: 'role',
        component: () => import(/* webpackChunkName: "perm" */ './RoleList'),
        name: 'rbac.role-list',
      },
      {
        path: 'permission',
        component: () => import(/* webpackChunkName: "perm" */ './PermissionList'),
        name: 'rbac.permission',
      },
      {
        path: 'api',
        component: () => import(/* webpackChunkName: "perm" */ './ApiManage'),
        name: 'rbac.api',
      },
    ],
  },
  {
    path: 'rbac/apply',
    component: Apply,
    name: 'rbac-apply',
    meta: { layoutType: 'header-content' },
  },
];
