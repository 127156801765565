export default {
  name: 'navigation',
  namespaced: true,
  state: {
    showMenu: false,
    breadcrumb: [],
    tagList: [],
  },
  getters: {
  },
  actions: {
    addTag({ commit, state }, tag) {
      const tagPathList = state.tagList.map(item => item.path);
      if (!tagPathList.includes(tag.path)) {
        commit('addTagM', tag);
      }
    },
    removeTag({ commit, state }, tag) {
      const findIndex = state.tagList.findIndex(item => item.path === tag.path);
      if (findIndex !== -1) {
        commit('removeTagM', findIndex);
      }
    },
    // 只保留index
    reserveTag({ commit }, index) {
      commit('reserveTagM', index);
    },
    // 清除所有标签
    cleanTag({ commit }) {
      commit('cleanTagM');
    },
    setBreadcrumb({ commit }, breadcrumb) {
      commit('setBreadcrumbM', breadcrumb);
    },
    setShowMenu({ commit }, showMenu) {
      commit('setShowMenuM', showMenu);
    },
  },
  mutations: {
    addTagM(state, tag) {
      state.tagList.push(tag);
    },
    removeTagM(state, index) {
      state.tagList.splice(index, 1);
    },
    reserveTagM(state, index) {
      state.tagList = [state.tagList[index]];
    },
    cleanTagM(state) {
      state.tagList = [];
    },
    setBreadcrumbM(state, breadcrumb) {
      state.breadcrumb = breadcrumb;
    },
    setShowMenuM(state, showMenu) {
      state.showMenu = showMenu;
    },
  },
};
