import { arrayify } from '@tencent/ui-core/lib/utils';
import { computed, reactive, readonly, shallowRef, toRef, toRefs, watch } from '@vue/composition-api';
import Vue from 'vue';
import { definePagePlugin } from '../types';
import { getFlowNodeSpecial } from './flowNodeSpecials';
import { toFullFlowPageState } from './store';
import { useSWR } from './utils/swr';
import { getFlowJobPageURL } from './utils/url';
import { jobSchema } from './dataSchema';
import { isVueParent } from '@components/builtinPagePlugins/flowPagePlugin/utils/isVueParent';
import { reloadFitDataButton } from '@components/builtinPagePlugins/flowPagePlugin/utils/reloadFitDataButton';
import appendCustomStyle from './utils/appendCustomStyle';
import isInputBlock from './utils/isInputBlock';


const pluginSet = new Set();

function useReload(uc, state) {
  return async (deep = true) => {
    const task = [state.jobSWR.reload()];
    if (deep) {
      // 这是根据插件的挂载位置得到的
      const renderer = uc.root;
      // 递归刷子页面片
      const subRenderer = Array.from(pluginSet).filter(child => child.$parent !== renderer)
        .filter(child => isVueParent(child, renderer));
      console.log('subRenderer=', subRenderer);
      task.push(...subRenderer.map(r => r.reload(false)));
      task.push(...subRenderer.map(r => r.nodeInstanceSWR.reload()));
    }
    try {
      await Promise.all(task);
    } catch (e) {
      console.log('[FlowPagePlugin] 页面片刷新失败', e);
    }
  };
}

// see [propsSchema] below
/**
 * @typedef {Object} Config
 * @property {string} flowId
 * @property {'nodePagelet' | 'layout'} pageType
 * @property {string[]} grantedFields - 要授权页面可看到哪些数据，例如 `["params", "state.xxx", "state.yyyy"]`
 * @property {string} [nodeId] - only when `pageType === 'nodePagelet'`
 */
export default definePagePlugin(
  {
    pluginId: 'internal:w-flow-page-plugin',
    name: '审批用页面能力',
    description: '用于渲染高级流程',
    propsSchema: {
      type: 'object',
      fields: [
        { id: 'flowId', type: 'string', title: '流程ID' },
        { id: 'nodeId', type: 'string', title: '流程节点ID' },
      ],
    },
  },
  async () => {
    const isInPageConfig = window.location.pathname.endsWith('/xy/project/pageconfig');

    const [
      normalSetup,
      devSetup,
    ] = (await Promise.all([
      import('./plugin').then(x => x.default),
      isInPageConfig && import('./plugin.designer').then(x => x.default),
    ]));

    /**
     * @param {Config | null | string} rawCfg
     */
    return (rawCfg, { pageId, projectId }) => ({
      install(uc) {
        if (!rawCfg || typeof rawCfg !== 'object') return console.warn('[xy] do not directly enable this plugin');
        if (!rawCfg.flowId) return console.warn('[xy] invalid plugin config for flowPagePlugin');

        /** @type {Config} */
        const config = {
          flowId: String(rawCfg.flowId),
          pageType: rawCfg.pageType || 'layout',
          grantedFields: arrayify(rawCfg.grantedFields).map(String),
          nodeId: String(rawCfg.nodeId || ''),
        };

        // 添加流程数据reload的按钮的fitData
        reloadFitDataButton(uc);

        const host = document.createElement('div');
        const inst = new Vue({
          name: 'FlowPagePlugin',
          parent: uc.root,
          setup() {
            const ans = {};

            Object.assign(ans, toRefs(normalSetup(uc, config, { pageId, projectId })));

            const isInsideEmbedded = uc.root.$parent?.$options?.name === 'WSysPagelet';
            if (devSetup && !isInsideEmbedded) {
              Object.assign(ans, toRefs(devSetup(uc, config, { pageId, projectId })));
            }

            const state = toFullFlowPageState(config, ans.stateFactory.value());
            ans.uc = shallowRef(uc);
            ans.config = readonly(config);
            ans.isInPageConfig = isInPageConfig;
            ans.state = state;

            // eslint-disable-next-line no-param-reassign
            uc['xy:flowPagePlugin'] = ans;

            const reload = useReload(uc, state);
            // ----------------------------------------------------------------
            // 挂在页面片的

            ans.jobInstance = reactive({
              id: toRef(state, 'jobId'),
              title: toRef(state, 'jobName'),
              status: computed(() => state.job?.status || ''),

              params: computed(() => state.jobParams),
              state: computed(() => state.job?.partialCtx?.state || {}),
              nodes: computed(() => state.job?.partialCtx?.nodes || {}),

              message: computed(() => state.job?.message || ''),
              creator: computed(() => state.job?.creator || ''),
              currentApprovers: computed(() => state.job?.currentApprovers || ''),
              relatedUsers: computed(() => state.job?.relatedUsers || ''),
              flowId: state.flowId,
              ctime: computed(() => state.job?.ctime),
              envId: computed(() => state.job?.envId || 'dev'),
              url: '',

              detailBlocks: computed(() => state.job?.detailBlocks || [{
                ctime: +new Date(),
                nodeId: 'start',
                type: 'start',
                nodePagelet: '',
                title: '开始',
              }]),
              reload,
              isWaitingForNextBlock: computed(() => {
                if (!state.job?.jobStatus) return false;
                if (['aborted', 'error', 'completed'].includes(state.job.jobStatus)) return false;
                const lastBlock = state.job.detailBlocks[state.job.detailBlocks.length - 1];
                // 非输入状态的block都需要等待
                return !isInputBlock(lastBlock);
              }),
            });
            ans.jobSchema = jobSchema;
            ans.reload = reload;
            watch(() => {
              const job = ans.jobInstance;
              return getFlowJobPageURL({
                flowId: job.flowId,
                envId: job.envId,
                jobId: job.id,
                projectId: job.projectId,
              });
            }, (url) => {
              ans.jobInstance.url = url;
            }, { immediate: true });

            ans.nodeSpecialInstSWR = useSWR(async () => {
              const { flowInfo } = state;
              if (!flowInfo) return;

              const nodeType = flowInfo.flowGraph?.nodes[state.nodeId]?.type;
              const Mod = await getFlowNodeSpecial(nodeType);

              /** @type {import('./flowNodeSpecials/base').FlowNodeSpecialBase} */
              const inst = new Mod({
                flow: flowInfo,
                nodeId: state.nodeId,
                isDesignMode: !!uc.isDesignMode,
              });
              await inst.init?.();
              return inst;
            }, {
              watch: () => ans.state.flowInfo,
            });
            ans.nodeInstanceSWR = useSWR(async () => {
              const inst = await ans.nodeSpecialInstSWR.waitForReady();

              let instance;
              let schema = {
                id: 'node',
                title: '当前流程节点',
                fields: [
                  { id: 'id', type: 'string', title: '节点ID' },
                ],
              };

              if (!inst) {
                instance = readonly({ id: state.nodeId });
              } else {
                const res = await inst.getDataSourceInstance({ state, uc });
                instance = res.instance;
                schema = {
                  ...res.schema,
                  ...schema,
                  fields: [
                    ...schema.fields,
                    ...res.schema.fields?.filter(x => x && x.id && x.id !== 'id') || [],
                  ],
                };

                Object.defineProperty(instance, 'id', {
                  enumerable: true,
                  configurable: true,
                  value: state.nodeId,
                });
              }

              return { instance, schema };
            }, {
              watch: () => ans.nodeSpecialInstSWR.value,
            });
            ans.nodeSchema = computed(() => ans.nodeInstanceSWR.value?.schema || {
              id: 'node',
              title: '当前流程节点',
              fields: [
                { id: 'id', type: 'string', title: '节点ID' },
              ],
            });
            ans.nodeInstance = computed(() => ans.nodeInstanceSWR.value?.instance || Object.freeze({
              id: config.nodeId || '',
            }));
            return ans;
          },
          created() {
            uc.$on('hook:beforeDestroy', () => {
              this.$destroy();
              host.remove();
            });

            // ----------------------------------------------------------------
            if (this.state.nodeId) this.nodeSpecialInstSWR.reload();

            // ----------------------------------------------------------------

            this.renderers = new Set();
            uc.hooks.pageletRendererCreated.tap('flowPagePlugin', (renderer) => {
              if (!renderer.ucIsRoot) return;

              this.renderers.add(renderer);
              this.updateDataSourceToRenderers([renderer]);
              renderer.$once('hook:beforeDestroy', () => {
                this.renderers.delete(renderer);
              });

              const xyPageContainer = renderer.wContext?.container;
              if (typeof xyPageContainer?.pageId === 'string') {
                // 这个页面是在页面片容器里的，需要同步标题出去
                renderer.$watch(
                  () => String(renderer.wContext.pageletInstance.getComputedTitle()),
                  (title) => {
                    xyPageContainer.$emit('flowPluginUpdateTitle', title);
                  },
                  { immediate: true },
                );
              }
            });

            this.recreateSource();
            this.$watch(() => this.state.pluginConfig.grantedFields, () => this.recreateSource());
            this.$watch(() => this.nodeInstance, () => this.updateDataSourceToRenderers());
          },
          methods: {
            async recreateSource() {
              /** @type {{ state: import('./store').FlowPageState }} */
              const { state } = this;

              await state.jobSWR.reload();
              await state.flowInfoSWR.reload();

              const grantedFields = state.pluginConfig.grantedFields || [];
              const stateFields = [];
              const nodesFields = [];

              if (grantedFields.length) {
                const { flowGraph } = state.flowInfo;

                // ---------

                if (grantedFields.includes('state')) stateFields.push(...flowGraph?.stateSchema || []);
                else {
                  grantedFields.forEach((p) => {
                    const mat = /^state\.(\w+)$/.exec(p);
                    if (mat) stateFields.push({ id: mat[1], type: 'any' });
                  });
                }

                // -------

                const nodeIds = [];
                if (grantedFields.includes('nodes')) nodeIds.push(...Object.keys(flowGraph?.nodes));
                else {
                  grantedFields.forEach((p) => {
                    const mat = /^nodes\.(\w+)$/.exec(p);
                    if (mat) nodeIds.push(mat[1]);
                  });
                }

                nodeIds.forEach((id) => {
                  // TODO: 要不要拉取 getAllFlowNodeMeta 的数据？但是可能运行时有安全问题
                  nodesFields.push({ id, type: 'object', title: flowGraph?.nodes[id]?.title, fields: [] });
                });
              }

              this.jobSchema = {
                ...this.jobSchema,
                fields: this.jobSchema.fields.map((f) => {
                  if (f.id === 'params') return { ...f, fields: [...state.flowInfo.flowGraph.params] };
                  if (f.id === 'state') return { ...f, fields: stateFields };
                  if (f.id === 'nodes') return { ...f, fields: nodesFields };
                  return f;
                }),
              };

              this.updateDataSourceToRenderers();
            },
            updateDataSourceToRenderers(targets) {
              /** @param {UICore.CRenderer} renderer */
              const doInject = (renderer) => {
                if (this.state.nodeId) {
                  // const lastNodeInst = renderer.node;
                  renderer.addDataSource({
                    id: 'node',
                    instance: this.nodeInstance,
                    schema: this.nodeSchema,
                  });

                  // console.log('update nodeInstance', this.nodeInstance, lastNodeInst);
                  // if (lastNodeInst) Vue.set(lastNodeInst, '_temp', 1);
                }

                renderer.addDataSource({
                  id: 'job',
                  instance: this.jobInstance,
                  schema: this.jobSchema,
                });
              };

              (targets || this.renderers).forEach(doInject);
            },
          },
        });

        uc.root.$once('hook:mounted', () => {
          document.body.appendChild(host);
          inst.$mount(host);
          pluginSet.add(inst);
          appendCustomStyle();
        });
        uc.root.$once('hook:beforeDestroy', () => {
          pluginSet.delete(inst);
        });
      },
    });
  },
);
